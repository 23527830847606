@import 'scss/variables';
@import 'scss/media';
@import 'scss/navigation';

@import 'scss/comp_video';
@import 'scss/page_whatwedo';
@import 'scss/page_about';
@import 'scss/page_articles';
@import 'scss/page_contact';
@import 'scss/page_digital';
@import 'scss/page_film';
@import 'scss/page_eventproduction';
@import 'scss/page_web';
@import 'scss/landingWebDesign';



/*
Table of Contents:

  1. Base Colour Classes
  2. Global Styles
  3. Components
    - 3.1 Footer
    - 3.2 Contact CTA
    - 3.3 Social Box
*/

.coral{
  color:$coral !important;
}
.navy{
  color:$navy !important;
}
.teal{
  color:$teal !important;
}
.pale-grey{
  color:$paleGrey !important;
}
.grey{
  color:$grey !important;
}
.cool-grey{
  color:$coolGrey !important;
}
.white{
  color: $white !important;
}
.bright-blue{
  color:$brightBlue;
}
.grey-bkgrd{
  background-color:$paleGrey;
}
.darkblue-bkgrd{
  background-color:$darkerBlue;
}
.coral-bkgrd{
  background-color:$coral;
}
.navy-bkgrd{
  background-color:$navy;
}
.teal-bkgrd{
  background-color:$teal;
}
.white-bkgrd{
  background-color:$white;
}
.err {
  border: 1px solid red !important;
}

/*/////////////////////// 2. Global Styles \\\\\\\\\\\\\\\\\\\\\\\*/

 html, body{
 // height:100%;
  margin:0;
  padding:0;
  @include proxNorm;
  font-size:16px;
  overflow-x:hidden;
  -webkit-overflow-scrolling: touch;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  ul, ol {
     list-style: inside !important;
  }

  &.blank_page{
    nav.navbar {
      display:none;
    }
    .SideNav {
      display:none;
    }
    .footer{
      display:none;
    }
    h1{
      font-size: 2.25rem;
    }
    a.btn{
      font-size: 1rem;
      line-height: 1.3;
      span {
        font-weight: 500;
      }
      &:active {
        background-color: $coral !important;
        border-color: $coral !important;
      }
    }
  }
}
html { overflow-y:auto; }
body { height: 100%; }

p{
  // margin:0;
  // padding:0;
}
a, a:link {
  color: $navy;
  @include proxBold;
  &:hover{
    color: $teal;
    text-decoration: underline;
  }

  &.noVisualLink {
    text-transform: none;
    color: #212329;
    font-weight: 300;
    display: inline;
    &:hover{
      color: #212329;
      text-decoration: none;
    }
  }
}

h1{
  @include proxNorm;

  &.smlH1 {
    font-size:1.5em;
    @include proxBold;
    color: $coral;
    text-transform: uppercase;
  }

  &.h1-global{
    font-size: 3.2em;
    color: $coral;
  }
}
.upper {
  text-transform: uppercase;
}

h2{
  font-size:1.5em;
  @include proxBold;
  color: $navy;
}
h3, h4 {
  font-size:1.3em;
  @include proxBold;
  color: $navy;
}
h4{
  font-size: 1.15em;
}
.upper {
  text-transform: uppercase;
}

.btn-primary{
  background-color: $teal;
  border-color: $teal;
  font-weight: bold;
  text-transform: uppercase;
  padding: 1.1rem 2rem;
  color: $white;

  &.btn-primary {
    color: $white;
    display: inline-block;

    &:hover{
      background-color: $teal;
      border-color: $teal;
      text-decoration: none;
      color: $white;
      -webkit-box-shadow: 0px 0px 10px 0px rgba(32,67,103,0.45);
      -moz-box-shadow: 0px 0px 10px 0px rgba(32,67,103,0.45);
      box-shadow: 0px 0px 10px 0px rgba(32,67,103,0.45);
    }
    &:active, &:focus {
      background-color: $teal;
      border-color: $teal;
      color: $white;
      -webkit-box-shadow: inset 0px 0px 10px 0px rgba(32,67,103,0.45);
      -moz-box-shadow: inset 0px 0px 10px 0px rgba(32,67,103,0.45);
      box-shadow: inset 0px 0px 10px 0px rgba(32,67,103,0.45);
      outline: none;
    }
  }
}
.concept {
  color: $white;
}
.vertCenterParent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 520px;
}

.vertText {
  -webkit-transform:rotate(180deg);
  -moz-transform:rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform:rotate(180deg);
  transform: rotate(180deg);
  @include proxBold;
  display: block;
  writing-mode: tb-rl;
  text-transform: uppercase;
  font-size: 1.2rem;
  margin: auto;
}

.modal-header{
  border-bottom:none;
  .close{
    padding: .1rem .5rem;
    margin: 0 -1rem -1rem auto;
    border: 3px solid #000 !important;
    border-radius: 50%;
    font-size: 1.6rem;
    outline: 0;
  }

    @include display_md {
        width: 150% !important;
    }

}

.modalForm {
  @include display_md {
    width: 150% !important;
  }
}

.content-start {
  margin-top: 200px;
}
.content-start-sub {
  margin-top: 152px;
}
.content-start-sub-gap {
  margin-top: 200px;
}

.content{
  position: relative;
  height:auto;
  min-height:100vh;
  width: 100%;
  max-width:1600px;
  margin-left:auto;
  margin-right:auto;
}

.careersLists {
  ul{
    padding-left:0;
  }
}

/*/////////////////////// 3. Components \\\\\\\\\\\\\\\\\\\\\\\*/


/*---------- 3.1 Footer ----------*/

.footer {
  margin-top: 60px;
  margin-bottom: 60px;
  color: $grey;

  h5{
    color: $grey;
    @include proxBold;
    a{
      font-size: 0.9rem;
      color: $grey;
      text-decoration: none;

      &:hover{
        color: $teal;
        text-decoration: none;
      }
    }
  }
}

/*---------- 3.2 Contact CTA ----------*/

.footerCTAText{
  background-color: $teal;
  color:#FFF;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 590px;

  .btn-primary{
    background-color: $navy;
    border-color: $navy;
    padding: 0.5rem 1.5rem;
  }

  h5 {
    font-size:1.8em;
    @include proxBold;
    text-transform: uppercase;
  }
}
.contact_img_1, .contact_img_2, .contact_img_3, .contact_img_4, .contact_img_5, .contact_img_6  {
  //background-image: url('../src/assets/images/getintouch_img_event.png');
  background-size: cover;
  display: block;
  width: 100%;
  height: 590px;
}


.contact_img_1{
  background-image: url('../src/assets/images/getintouch_img_colleagues.2021.png');
  background-position-y: center;
}
html.webp.no-jpeg2000 {
  .contact_img_1{
    background-image: url('../src/assets/images/webp/getintouch_img_colleagues.2021.webp');
  }
}
html.jpeg2000.no-webp{
  .contact_img_1{
    background-image: url('../src/assets/images/jp2/getintouch_img_colleagues.2021.jp2');
  }
}
// -------

.contact_img_2{
  background-image: url('../src/assets/images/dataScreen.png');
  background-position-x: center;
}
html.webp.no-jpeg2000 {
  .contact_img_2{
    background-image: url('../src/assets/images/webp/dataScreen.webp');
  }
}
html.jpeg2000.no-webp{
  .contact_img_2{
    background-image: url('../src/assets/images/jp2/dataScreen.jp2');
  }
}
// ------

.contact_img_3{
  background-image: url('../src/assets/images/getintouch_img_event.png');
}
html.webp.no-jpeg2000 {
  .contact_img_3{
    background-image: url('../src/assets/images/webp/getintouch_img_event.webp');
  }
}
html.jpeg2000.no-webp{
  .contact_img_3{
    background-image: url('../src/assets/images/jp2/getintouch_img_event.jp2');
  }
}
// -------


.contact_img_4{
  background-image: url('../src/assets/images/getintouch_img_film.png');
  background-position-x: center;
}
html.webp.no-jpeg2000 {
  .contact_img_4{
    background-image: url('../src/assets/images/webp/getintouch_img_film.webp');
  }
}
html.jpeg2000.no-webp{
  .contact_img_4{
    background-image: url('../src/assets/images/jp2/getintouch_img_film.jp2');
  }
}
// ------

.contact_img_5{
  background-image: url('../src/assets/images/contact_img_camera.png');
}
html.webp.no-jpeg2000 {
  .contact_img_5{
    background-image: url('../src/assets/images/webp/contact_img_camera.webp');
  }
}
html.jpeg2000.no-webp{
  .contact_img_5{
    background-image: url('../src/assets/images/jp2/contact_img_camera.jp2');
  }
}
// ------

.contact_img_6{
  background-image: url('../src/assets/images/getintouch_img_web.png');
}
html.webp.no-jpeg2000 {
  .contact_img_6{
    background-image: url('../src/assets/images/webp/getintouch_img_web.webp');
  }
}
html.jpeg2000.no-webp{
  .contact_img_6{
    background-image: url('../src/assets/images/jp2/getintouch_img_web.jp2');
  }
}
//-----

.contactModal {
  .modal-content {
    border-radius: 0;
    border:none;

    .modal-header {
      padding-left: 0 !important;
      border-bottom: none;
      h3{
        @include proxBold;
      }
    }
    .modal-body{
      padding:0 1rem 0 0;

      .form-group {
        margin-bottom: 0.5rem;
      }
      .form-text{
        display: inline-block;
        margin-top: .20rem;
      }
      .text-muted{
        color: #9B9B9B !important;
      }
      .contactCol1{
        background-color: $teal;
        color: $white;
        font-size: 0.95rem;
        hr {
          border-top: 1px solid $white;
        }
        a {
          color:$white;
        }
        @include display_md {
          margin-left: 4% !important;
          max-width: 95% !important;
          margin-bottom: 1% !important;
        }
        @include display_sm {
          margin-left: 4% !important;
          max-width: 95% !important;
          margin-bottom: 1% !important;
        }
        @include display_xs {
          margin-left: 4% !important;
          max-width: 95% !important;
          margin-bottom: 1% !important;
        }

        @media only screen and (max-width: 460px) and (min-width: 421px) {
          margin-left: 4% !important;
          max-width: 95.5% !important;
          margin-bottom: 1% !important;
        }
         @media only screen and (max-width: 420px) and (min-width: 390px) {
          margin-left: 4% !important;
          max-width: 96% !important;
          margin-bottom: 1% !important;
        }
        @media only screen and (max-width: 389px) and (min-width: 360px) {
          margin-left: 4.6% !important;
          max-width: 95% !important;
          margin-bottom: 1% !important;
        }
         @media only screen and (max-width: 359px) and (min-width: 320px) {
          margin-left: 5% !important;
          max-width: 94.8% !important;
          margin-bottom: 1% !important;
        }
         @media only screen and (max-width: 320px)  {
          margin-left: 6% !important;
          max-width: 93.8% !important;
          margin-bottom: 1% !important;
        }

      }
      .contactCol2{
        // @include display_sm{
        //   margin-left: 1rem;
        // }
        @include display_md{
          margin-left: 1rem;
        }

        @include display_sm {
          margin-left: 4% !important;
          max-width: 95% !important;
          margin-bottom: 1% !important;
        }
        @include display_xs {
          margin-left: 4% !important;
          max-width: 95% !important;
          margin-bottom: 1% !important;
        }
      }
      .btn-primary{
        padding: 0.5rem 2rem;
        font-size: 1rem;
      }
      label{
        color: #9B9B9B;
      }
    }
  }


}

.carousel-indicators {
  list-style: none !important;
  height: 20px;
  bottom: -35px;
  li {
    background-color: $coral;
  }
}



.sitemap {
  ul{
    padding-left: 1.5rem;
    li{
      list-style: none;

      a{
        font-weight: normal;
      }
    }
  }
}


.introduction-paragraph {
  color: #4A4A4A;
  font-size: 1.4em;
}

.img-project-row2 {
  width: 100% !important;
}

.digit-content {
  @include display_md {
      margin-top: 76px !important;
  }
}
.ie-class{
  background-attachment: scroll !important;
}

#root.ie-class{

  .rowWithTransparentBgAnimation{
    background-attachment: scroll !important;
  }
  .rowWithTransparentBgCorporate{
    background-attachment: scroll !important;
  }
  .rowWithTransparentBgCreative{
    background-attachment: scroll !important;
  }
  .rowWithTransparentBgDSCP{
    background-attachment: scroll !important;
  }
  .rowWithTransparentBgEventProdComp  {
    background-attachment: scroll !important;

    @include display_md {
      background-position-y: 0% !important;
    }
    @include display_lg {
      background-position-y: 0% !important;
    }
  }
  .rowWithTransparentBgWeb {
    background-attachment: scroll !important;
  }
  .rowWithTransparentBgVideo2 {
    background-attachment: scroll !important;

      @include display_md {
        background-position-y: 0% !important;
      }
      @include display_lg {
        background-position-y: 0% !important;
      }
  }

  .card-img-top {
      height: auto !important;

      @include display_max_md {
          max-height: none !important;
          height: auto !important;
          margin-top: 5px !important;
      }
      @include display_xs {
          width: 0% !important;
      }
  }
  .card-body {
      padding-top: 40px !important;

      @include display_max_md {
        margin-top: 30px !important;
        padding-top: 0% !important;
      }
  }
  .card {
      @include display_max_md {
        height: 670px !important;
      }
  }
  .cardImg {
      @include display_max_md {
        height: 270px !important;
      }
      @include display_xs {
        background-position-y: 20% !important;
      }
  }

  .projects .item .workItem {

      @include display_md {
        max-width: 150% !important;

      }
  }


}

/*---------- Print CSS ----------*/
@media print {

  a::after{
    content: " (" attr(href) ") ";
  }

  .animated, .printVisible {
    display: block !important;
  }
  .fadeOut {
    animation: none;
  }
  // #videoBkgrdPlaceholder {
  //   display: none;
  // }
  .projects {
    .item {
      height: inherit;
      .workItem {
        height: 270px;
        position: relative;
        display: block;
      }
      .workItemHover {
        display:block;
        position: relative;
      }
    }
  }
  // .videoHomePageFull, iframe{
  //   display: none;
  // }
  .teamProfile,
  .locationImages .box1, .locationImages .box2, .locationImages .box3 {
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
  }

}
//_----------------------------------------
@mixin generalForSafariOnIPad {
  @media only screen and (width : 1024px) {
    background-attachment: initial;
    background-position-y: 0% !important;
    //min-height: 400px !important;
  }
  @media only screen and (width : 768px) {
    background-attachment: initial;
    background-position-y: 0% !important;
  }
}
@mixin useForSafariUpTo1366{
  @media only screen and (max-width : 1366px) {
    background-attachment: initial;
  }
}

#root.safari-class {
  @media only screen and (max-width : 1366px) {

    .videoHomePageShrink {
        width: 100% !important;
        margin-top: 0px !important;
    }
     .txtHomePageVideoOverlayNavy {
        a { color: white !important; }
    }
    .social-svg-mask {
        fill: white !important;
    }
    .sideClockEdi , .sideClockDub, .sideClockSan {
      color: white !important;
    }

  }


  .rowWithTransparentBgAnimation{

    @include useForSafariUpTo1366;

    @media only screen and (width : 1024px) {
      padding-bottom: 176px !important;
      margin-bottom: -13% !important;
      @include generalForSafariOnIPad;
    }
    @media only screen and (width : 768px) {
      margin-bottom: 0% !important;
      @include generalForSafariOnIPad;
    }
  }
  .rowWithTransparentBgCorporate{

    @include useForSafariUpTo1366;

    @media only screen and (width : 1024px) {
      @include generalForSafariOnIPad;
      margin-bottom: -5% !important;

    }
    @media only screen and (width : 768px) {
      @include generalForSafariOnIPad;
      margin-bottom: -11% !important;
    }
  }
  .rowWithTransparentBgCreative{

    @include useForSafariUpTo1366;

    @media only screen and (width : 1024px) {
      padding-bottom: 176px !important;
      margin-bottom: -23% !important;
      @include generalForSafariOnIPad;
    }
    @media only screen and (width : 768px) {
      margin-bottom: -15% !important;
      @include generalForSafariOnIPad;

    }
  }
  .rowWithTransparentBgDSCP{

    @include useForSafariUpTo1366;

    @media only screen and (width : 1024px) {
      @include generalForSafariOnIPad;
      padding-bottom: 176px !important;
      margin-bottom: -20% !important;
      min-height: 400px !important;
    }
    @media only screen and (width : 768px) {
      @include generalForSafariOnIPad;
      margin-bottom: -11% !important;

    }
  }
  .rowWithTransparentBgEventProdComp  {

    @include useForSafariUpTo1366;

      @media only screen and (width : 1024px) {
        @include generalForSafariOnIPad;
        margin-bottom: -23% !important;
      }
      @media only screen and (width : 768px) {
        @include generalForSafariOnIPad;
        margin-bottom: -41% !important;
      }
      @include display_md {
        background-position-y: 0% !important;
      }
      @include display_lg {
        background-position-y: 0% !important;
      }
  }
  .rowWithTransparentBgWeb {

      @include useForSafariUpTo1366;

      @media only screen and (width : 1024px) {
        @include generalForSafariOnIPad;
        padding-bottom: 176px !important;
        margin-bottom: -3% !important;

      }
      @media only screen and (width : 768px) {
        @include generalForSafariOnIPad;
        margin-bottom: -5% !important;

      }
  }
  .rowWithTransparentBgVideo2 {

    @include useForSafariUpTo1366;

    @media only screen and (width : 1024px) {
      @include generalForSafariOnIPad;
      margin-bottom: -13% !important;
      min-height: 500px !important;
    }
    @media only screen and (width : 768px) {
      @include generalForSafariOnIPad;
      margin-bottom: -41% !important;
    }

    @include display_md {
      background-position-y: 0% !important;
    }
    @include display_lg {
      background-position-y: 0% !important;
    }
  }
}
