.singleCitationPink{
    background-image: url('../assets/images/quotePink.png');
    background-repeat: no-repeat;
    background-size: 70%;
    background-position: center;


    @include display_max_sm {
        margin-top: -20%;
        width: 80% !important;      
    }
}

html.webp.no-jpeg2000 {
    .singleCitationPink{
        background-image: url('../assets/images/webp/quotePink.webp');
    }
  }
  html.jpeg2000.no-webp{
    .singleCitationPink{
        background-image: url('../assets/images/jp2/quotePink.jp2');
    }
  }

.pinkLeft {
    @include display_xs {
        margin-top: -30% !important;
    }
    @include display_xl {
        background-size: 80% !important;
    }
}
.center-paragraph{
    @include display_max_lg {
        padding: 10% 0 !important;
    }
    padding: 0 !important;
}
.quoteLeft {
    @include display_md {
    margin-bottom: 30% !important;
        &:first-child {
            margin-top: 0% !important;
        }
    }
    @include display_sm {
    margin-bottom: 30% !important;
        &:first-child {
            margin-top: 0% !important;
        }
    }
    @include display_xs {
    margin-bottom: 30% !important;
        &:first-child {
            margin-top: 0% !important;
        }
    }
}

.singleCitationPink-sm{
    background-image: url('../assets/images/quotePink.png');
    background-repeat: no-repeat;
    background-size: 80%;
    background-position: center;
    height:268px;
    display: flex;

    div {
        text-align: center;
        // padding: 30% 0 30%;
        align-self: center;
    }

    @include display_max_md {
        margin-bottom: 30%;
        margin-top: 30%;
        background-size: 63%;
    }
    @include display_max_md {
        margin-bottom: 20%;
        margin-top: 10%;
        padding: 0 12%;
    } 
    @include display_xs {
        background-size: 58%;
        margin-bottom: 10%;
    }
    @include display_sm {
        margin-bottom: 10%;
    }
  
}

html.webp.no-jpeg2000 {
    .singleCitationPink-sm{
        background-image: url('../assets/images/webp/quotePink.webp');
    }
  }
html.jpeg2000.no-webp{
    .singleCitationPink-sm{
        background-image: url('../assets/images/jp2/quotePink.jp2');
    }
}



.singleCitationBlue{
    background-image: url('../assets/images/quoteBlue.png');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;

    @include display_max_lg {
        margin-bottom: 30%;
    }
    @include display_max_sm {
        margin-top: -40%;
        width: 80% !important;
        
    } 
    @include display_max_xl {
        background-size: 100%;
        
    }
}

html.webp.no-jpeg2000 {
    .singleCitationBlue{
        background-image: url('../assets/images/webp/quoteBlue.webp');
    }
  }
html.jpeg2000.no-webp{
    .singleCitationBlue{
        background-image: url('../assets/images/jp2/quoteBlue.jp2');
    }
}


.singleCitationPink{

        background-size: 100%;
  
}

.blueRight {
    @include display_xs {
        margin-top: -55% !important;
        margin-bottom: 40% !important;
    }
    @include display_xl {
        background-size: 80% !important;
    }
}

.singleCitationBlue-sm{
    background-image: url('../assets/images/quoteBlue.png');
    background-repeat: no-repeat;
    background-size: 80%;
    background-position: center;
    height:268px;
    display: flex;
    
    div {
        text-align: center;
        // padding: 30% 0 30%;
        align-self: center;
    }

    @include display_max_md {
        margin-bottom: 30%;
        margin-top: 30%;
        background-size: 63%;
    }
    @include display_max_md {
        margin-bottom: 10%;
        margin-top: 15%;
        padding: 0 12%;
    }
    @include display_xs {
        background-size: 58%;
        margin-top: 10%;
    }
    @include display_sm {
        margin-top: 10%;
    }
}

html.webp.no-jpeg2000 {
    .singleCitationBlue-sm{
        background-image: url('../assets/images/webp/quoteBlue.webp');
        }
  }
html.jpeg2000.no-webp{
    .singleCitationBlue-sm{
        background-image: url('../assets/images/jp2/quoteBlue.jp2');
    }
}

.generalColumnStyle{
    font-size: 1.2em;
  
}
.generalColumnStyleFlex{
    font-size: 1.2em;
    
    display: flex;
    justify-content: center;
 
}


.row25  {
    @include display_max_lg {
        margin-top: -20%;
    } 
    @include display_xs {
       padding-bottom: 0% !important;
    }
     @include display_sm {
       padding-bottom: 0% !important;
    }
    @include display_md {
        margin-top: -25%;
    } 
    @include display_lg {
        margin-bottom: -20%;
    } 
    @include display_xl {
        margin-bottom: -16%;
    }
}


.data-sign {
    @include display_xs {
        margin-top: 28% !important;
        margin-bottom: -72% !important;
    } 
    @include display_sm {
        margin-top: 25% !important;
        margin-bottom: -40% !important;
    } 
     @include display_md {
        margin-top: 25% !important;
        margin-bottom: -40% !important;
    } 
    @include display_xl {
        transform: translateY(20%) !important;
    }
} 



.greyRow2 {
    @include display_max_lg{
        margin-top: -30% !important;
        
        .leftColumnDSCP, .leftColumnAnim, .leftColumnCorp{
            order: 2;
        }
        .rightColumnDSCP, .rightColumnAnim, .rightColumnCorp {
            order:1;
        }
        .rightSideImage {
            margin-top: -50%;
        }
     
    }
    @include display_lg {
        margin-top: -10% !important;
    }

}
.leftColumnDSCP {
  
    @include display_xs {
       margin-top: 20% 
    } 
}

.left-dscp2 {
    @include  display_sm {
        margin-top: 20% !important 
     }
     @include  display_md {
        margin-top: 20% !important 
     }
}

.l-DSPC {
    @include  display_md {
       margin-top: 30% !important 
    } 
     @include  display_sm {
       margin-top: 30% !important 
    }
    
}
.offline {
    @include display_max_lg {
        margin-top: 5%;
    } 
    @include display_xs {
        margin-top: 50%;
    }
}
.takealook {
    @include display_max_lg {
        margin-top: -10%;
    }
}


.recentProjectColumn{
    @include display_max_lg{
        width: 80%
    }
}

.navyColumnRight {
    @include display_max_lg{
        padding-top: 30%;
        padding-bottom: 10%;
        
    }
    @include display_md {
        padding-top: 10% !important;
      
    } 
    @include display_sm {
        padding-top: 0% !important;
        padding-bottom: 0% !important;
    } 
    @include display_xs {
        padding-top: 15% !important;
        padding-bottom: 15% !important;
      
    }
}
.navyColumnRight2 {
    @include display_lg{
        padding-top: 30%;
        padding-bottom: 0%;
        
    }
    @include display_md {
        padding-bottom: 10% !important;
      
    } 
    @include display_sm {
        padding-top: 0% !important;
        padding-bottom: 10% !important;
    } 
    @include display_xs {
        padding-top: 5% !important;
        padding-bottom: 25% !important;
      
    }
}


.laptop2 {

    @include display_md{
        margin-top: -33% !important;
        margin-bottom: -5% !important;
       
    }
     @include display_sm{
       margin-top: -7% !important;
       margin-bottom: 5% !important;
       
    }
   
   
}

.weHaveHelped {
    @include display_max_lg{
        padding-top: 30%;
      }
}



//---------------- message Box --------

.messageBox{
    border: 1px;
    padding: 25px 25px 40px 25px;
    background-color: $white;
    margin: 10% 10px;
    

    -webkit-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.5);
    -moz-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.5);
    box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.5);

    @include display_md {
        width:90%;
        margin-left: 5%;

        & img {
            width: 45px !important;
        }
    }
    @include display_lg{
        width:80%;
        margin-left: 10% !important;
    }
    @include display_xl{
        width:80%;
        margin-left: 10%;
    }
    @include display_sm{
        margin-left: 5%;
        margin-right: 5%;
    }
    @include display_max_sm{
        max-width:90%;
        margin-left: 5%;
        margin-right: 5%;
       
    }

        img {
            max-width: 55px;
            margin-left: 5px !important;
            
            @include display_max_sm {
                margin-bottom: 10px !important;
                
            }

        }
 
    
}

.bothInline{
    display: inline-block;
    padding-right: 15px;
    justify-content: center;
    
    img{
        margin: 0 auto;
    }
    @include display_max_sm{
        
        display: flex; 
        img {
            width: initial;
            height: 60%;
        }
        p {
            width: 100%;
            float: right !important;
            margin-top: -8px;
            text-align: right;
            height: 100%;
            transform: rotate(0deg) !important;
        }
    }




}

.textForMessage{
    padding-left: 15px;
    @include display_max_sm {
        padding-left: 0 !important;
    }
}

.verticalText{
    transform: rotate(-180deg);
    writing-mode: tb-rl;
    font-weight: 700;
    font-size: 1.1em;
    color: $navy;
    display: flex; 
    justify-content: center;
    margin-top: 20px;
    text-transform: uppercase;
    margin-left: 20px;
    //margin: 20px auto;
    

  

    @include display_max_sm {
       // transform: rotate(0deg) !important;
        margin-top: 5px;
        margin-left: 10px !important;
        flex-direction: row;
        writing-mode: unset;
        justify-content: flex-end; 
        writing-mode: horizontal-tb;
        
    }
    @include display_sm {
        white-space: nowrap;
    } 
     @include display_md {
        white-space: nowrap;
        margin-left: 12px !important;
    } 
    
}
  
.bottomStrip {
    height: 15px;
    margin-left: -25px;
    margin-right: -25px;
    background-color: $teal;
    bottom:0;
    margin-bottom: -40px;
}

// --------------------------------------

.messageBoxesColumn{
    
    margin-top: -30% !important;

    @include display_lg {
        margin-top: -35% !important;
    }
    @include display_xl {
        margin-top: -40% !important;
    }

    @media only screen and (min-width: 2001px) {
        margin-top: -45% !important;
    }

   
}

.servicesMessageForLanding{
    padding: 10% 0;
    color: $darkerBlue;
    font-size: 2em;
    line-height: 1.4;

    @include display_max_sm{
        font-size: 1.5em;
    }
}

.asAleadingCompany {
    
    @include display_max_lg {
        margin-top: 5%;
    }
}

.anAwardWinnig {
    
    @include display_max_md {
        margin-top: -5%;
    }
}

.asAleadingCompany p:first-of-type {
    margin-top: 0;
    margin-bottom: 10%;

   
}
.lamp {
   
     @include display_lg{
        width: 120% !important;
        margin-left: -10% !important;
        margin-top: -5% !important;
    } 
    @include display_xl{
        margin-top: -5% !important;
    }
   
    
}

.lamp3 {
   

    @include display_xl{
        width: 120% !important;
        margin-left: -10% !important;
        margin-top: 10% !important;
    }
    @include display_lg {
        width: 120% !important;
        margin-left: -10% !important;
        margin-top: 10% !important;
    }
     @include display_md {
        width: 120% !important;
        margin-left: -5% !important;
    }
    @include display_sm {
        width: 100% !important;
    // margin-left: 10% !important;
        margin-bottom: -5% !important;
        margin-top: -5% !important;
    } 
    @include display_xs {
        margin-left: -46px !important;
        width: 123% !important;
        margin-top: -10% !important;
        margin-bottom: -5% !important;
    }
}
.lamp5 {
    @include display_md {
        width: 100% !important;
       // margin-left: -10% !important;
    } 
    @include display_sm {
        width: 100% !important;
        margin-top: -10% !important;
       // margin-bottom: -10% !important;
    } 
    @include display_xs {
            margin-left: -46px !important;
            width: 123% !important;
            margin-top: -10% !important;
            margin-bottom: 5% !important;
    }
}
.noMatter {
    @include display_xl {
        margin-top: -5%;
    } 
    @include display_lg {
        margin-top: -20%;
    }
}


.twoQuotes{
    display: flex; 
    flex-direction: row;
    justify-content: space-around;
    padding-bottom: 15% !important;

    @include display_xl {
        padding-top: 0% !important;
        padding-bottom: 10% !important;
    } 
    @include display_max_lg {
        padding-bottom: 0;
        margin-bottom: -30%;
        margin-top: 20% !important;
    }

    @include display_max_sm {
        flex-direction: column;
        margin-left: 15%;
        padding-bottom: 20%;
        margin-top: 22% !important;
       
    }
    @include display_sm {
        margin-top: -2% !important;
        margin-bottom: -47% !important;
    } 
     @include display_xs {
        margin-top: -15% !important;
        margin-bottom: -40% !important;
    }
    @include display_md {
        margin-top: -2% !important;
        margin-bottom: -47% !important;
    } 
    @include display_lg {
        margin-top: 0% !important;
        
    }

     @media screen and  (min-width:2200px) {
        margin-top: 2% !important;
        margin-bottom: 5% !important;
    } 
     @media screen and  (min-width:1201px)and  (max-width:1400px) {
        margin-top: 0% !important;
        margin-bottom: -5% !important;
    }

}

.twoQuotes2 {
     @media screen and (max-width: 1270px) and (min-width:1200px) {
        margin-top: 10% !important;
    }
  
}


// --------------------- IMAGES -------------------------------

.imageDrop{
    transform: translateY(50%);
   
}
.imageUp{
    transform: translateY(-70%);
}
.ourApproach {
    @include display_max_lg {
        margin-top: 5%;
    }
}

.production {
    @include display_lg{
        margin-left: 0% !important;
        transform: translateY(30%) !important;
    }
   

  
}


.greyRowNarrow {
    margin: -10% 0;
}


.greyRow{

    display: flex;

    @include display_max_lg{
        
        .leftColumnDSCP, .leftColumnAnim, .leftColumnCorp{
            order: 2;
        }
        .rightColumnDSCP, .rightColumnAnim, .rightColumnCorp {
            order:1;
        }
        .rightSideImage {
            margin-top: -50%;
        }
     
    }
    @include display_md {
        margin-top: 20% !important;
    } 
    @include display_sm {
        margin-top: 25% !important;
    }
}


.atProduction35 {

    @include display_xs{
        margin-top: 0 !important;
        margin-bottom: -2% !important;
    }
     @include display_sm{
        margin-top: -3% !important;
        margin-bottom: 0% !important;
    } 
     @include display_md{
        margin-top: -3% !important;
        margin-bottom: -2% !important;
    } 
     @include display_lg{
        margin: 6% 0% 25% !important;
    }
   
}

.atProduction36 {
    @include display_xs{
       margin-bottom: 1% !important;
    }
     @include display_sm{
       margin-top: -3% !important;
    } 
     @include display_md{
        margin-top: -3% !important;
    } 
     @include display_lg{
       
    }
}

.project-35633{
    @include display_xs{
        margin-top: 3% !important;
        margin-left: -36px !important;
        width: 120% !important;
        margin-bottom: 1% !important;
     }
      @include display_sm{
        margin-top: 3% !important;
        width: 74% !important;
        margin-left: 14% !important;
        margin-bottom: 1% !important;
     } 
      @include display_md{
        margin-top: 3% !important;
        width: 74% !important;
        margin-left: 13% !important;
        margin-bottom: -1% !important;
     } 
      @include display_lg{
        
      }
}

.rightColumnDSCP {
    @include display_max_lg {
        margin-bottom: -25% !important;
    }
}


.laptop3 {
    
    @include display_xs {
        margin: 0 -17%;
    } 
    @include display_sm {
        height: 150% !important;
        margin-top: -20%;
    }
    @include display_md {
        height: 150% !important;
        margin-top: -20%;
    }
}
.onTweeter2 {
    @include display_xl {
        transform: translateY(40%);
    }
    @include display_lg {
        transform: translateY(50%);
    }    
}

.firstRow-digitSign {
   
    @include display_xl {
        padding-bottom: 0% !important;
    }
    @include display_md {
        margin-top: 20% !important;
    } 
    @include display_xs {
        margin-bottom: -20% !important;
    }
    @media only screen and (max-width:1800px) and (min-width: 1501px) {
        padding-bottom: 2% !important;
    }
    @media screen  and(min-width: 1201px)and(max-width: 1400px) {
        margin-top: 10% !important;
      }
      @media screen  and(min-width: 2201px) {
        margin-top: -5% !important;
      }
}

#content2 {
    @media screen  and(min-width: 1271px)and(max-width: 1400px) {
        margin-bottom: 20% !important;
      }
}

.middleRow-digitSign{
    @include display_xl {
        margin: -4% 0 -5% 0 !important;
    }
    @media only screen and (max-width:1500px) and (min-width: 1201px) {
        margin: 0 0 -2% 0 !important;
    }
}

.usability2 {
    @include display_lg {
        padding-top: 25%;
    }
    @include display_xl {
        padding-top: 30% !important;
        margin-bottom: -10% !important;
    }  
}


.afterQuotes{
margin-top: -30%;
    @include display_xl {
        margin-top: -200px !important;
    }
    @include display_max_lg {
        margin-top: 2% !important;
        margin-bottom: 20% !important;
    } 
    @include display_lg {
        margin-top: -12% !important;
        margin-bottom: 10% !important;
    }
}
.quoteAlone {
    width: 51%;
    height: 100%;
    padding-top:20%;
    padding-bottom:25%;

    @include display_max_lg{ 
    width: 60% !important;
    }
    
   
    @include display_lg {
        width:70% !important;
        margin-top: 20% !important;
        padding-bottom:25%;
    }
    @include display_md {
        width: 45% !important;
        
        margin-bottom: -15% !important;
    }  
     @include display_sm {
       
        margin-top: 5%;
        margin-bottom: -6%;
    } 
     @include display_xs {
        width: 80% !important;
        margin-top: -2%;
        margin-bottom: 7%;
    } 
   
    
    @media only screen and  (min-width: 2100px) {
        width: 40%;
    }
    @media only screen and  (min-width: 1200px) and  (max-width: 1500px) {
        width: 55% !important;
    }
    
}

.quotationText {

    @include  display_xs {
        margin-top: 0% !important;
    }
     @include  display_sm {
        margin-top: 0% !important;
    } 
    @include  display_md {
        margin-top: -15% !important;
    }
    @include  display_lg {
        margin-top: 15% !important;
    }
}

.dive2 {
    margin-bottom: 5% !important;

    @include display_xs {
       margin-top: 0% !important;
       margin-bottom: 0% !important;
    }
    @media only screen and  (max-width: 400px){
        width: 130% !important;
        margin-right: -15px !important;
        margin-left:   -30px !important;
        padding-right: 0% !important;
        padding-left: 0% !important;
      
    }
}
.singleQuoteRow {

        margin-top: -2% !important;

        @include display_xl {

        }
        @media only screen and (min-width: 1201px) and (max-width: 1700px){
            margin-top: -10% !important;
          
        }
    
    
}
.textRow {
    margin-bottom: 0% ;
    @include display_lg {
        margin-top: -10%;
    }
    @media only screen and (min-width: 1201px) and (max-width: 1400px){
        margin-top: -10% !important;
      
    }
}

@mixin transparentBg($name) {
    background-image: url('../assets/images/#{$name}.png');
    background-repeat: round;
    background-size: cover;
    background-attachment: fixed;
    background-position-x: left;
    padding-top: 20%;
    overflow: hidden;
    height: 100%; 


    @include display_xl {
        background-repeat: round;
        background-size: cover;
    }
    @media only screen and  (min-width: 1201px) and (max-width: 1600px) { 
        background-repeat: inherit;
        background-size: cover
    }

    @include display_max_md {
        background-repeat: no-repeat;
        background-size: contain;
       
    }
    @include display_lg {
        background-repeat: no-repeat;
        background-size: contain;
       
    }
}

html.webp.no-jpeg2000 {
    @mixin transparentBg($name) {
        background-image: url('../assets/images/webp/#{$name}.webp');
    }
  }
html.jpeg2000.no-webp{
    @mixin transparentBg($name) {
        background-image: url('../assets/images/jp2/#{$name}.jp2');
    }
}

.rowWithTransparentBgWeb {
    @include transparentBg(gpcaForumBg);
    padding-bottom: 32%;

    @include display_xl {
        padding-bottom: 8% !important;
        padding-top: 21% !important;
    }
    @media only screen and  (min-width: 1601px)and  (max-width: 2149px) {
        padding-bottom: 8% !important;
        padding-top: 17% !important;
    }
    @media only screen and  (min-width: 2150px) {
        padding-bottom: 8% !important;
        padding-top: 15% !important;
    }

    
     @include display_lg {
        padding-bottom: 15% !important;
    } 
    @include display_md {
        padding-bottom: 25% !important;
        background-repeat: no-repeat !important;
        background-size: contain !important;
    }
    @include display_sm {
        padding-bottom: 15% !important;
    }
    @include display_xs {
        padding-bottom: 15% !important;
    }

 
    
}

.bottom-row-dscp {
    @include display_xl {
        padding-bottom: 3%;
    }
}

.rowWithTransparentBgDSCP {
    @include transparentBg(gpcaForumBg);

    @include display_xl {
        padding-top: 1% !important;
    }

    @include display_lg {
        background-position-y: 0% !important;;
        padding-top: 15% !important;
    }
    @include display_md {
        background-position-y: 0% !important;
        padding-bottom: 5%;
        background-repeat: no-repeat !important;
        background-size: contain !important;
        padding-top: 13% !important;
    }
    @include display_sm {
        background-position-y: 0% !important;
        padding-bottom: 5%;
        padding-top: 13% !important;
    }
    @include display_max_sm {
        background-position-y: 0% !important;
        padding-bottom: 20%;
        padding-top: 13% !important;
    }
   
}

.rowWithTransparentBgCreative {
   

    @include transparentBg(transparentBGCreative);

    padding-bottom: 0% !important;
    @include display_lg {
         //background-attachment: initial; - for Ipad
        padding-bottom: 0% !important;
        margin-top: -8% !important; 
    }  
    @include display_md {
        background-repeat: no-repeat !important;
        background-size: contain !important;
        margin-top: -20% !important;
        padding-bottom: 0% !important;
        margin-bottom: -10% !important;
    }
    @include display_sm {
        padding-bottom: 0% !important;
        margin-top: -28% !important;
        margin-bottom: -20% !important;
    } 
     @include display_xs {
        padding-bottom: 0% !important;
        margin-bottom: -20% !important;
        margin-top: -30px;
    }  
   
   
    @media only screen and (min-width: 200px) and (max-width: 400px){
        padding-bottom: 0% !important;
        margin-bottom: -10% !important;
    } 
    @media only screen and (min-width: 1201px) and (max-width: 1400px){
        padding-bottom: 20% !important;
    } 
    @media only screen and (min-width: 1401px) and (max-width: 1650px){
        padding-bottom: 0% !important;
    }
    @media only screen and (min-width: 1651px) {
        padding-bottom: 0% !important;
    }
    @media only screen and  (min-width: 1201px)  { 
        background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 0% !important;
       
    }
    @media only screen and (min-width: 1201px) and (max-width: 1438px){
        background-repeat: no-repeat;
        background-size: contain;
      
    }

}
.rowWithTransparentBgCorporate {
    @include transparentBg(transpatentBgcorporate);

    @include display_max_md {
        background-position-y:0px !important;
    } 
    @include display_md {
        background-repeat: no-repeat !important;
        background-size: contain !important;
        background-position-y:76px !important;
    } 
    @media only screen and  (min-width: 1201px)  { 
        background-repeat: inherit;
        background-size: cover

    }
    @media only screen and (min-width: 1201px) and (max-width: 1438px){
        background-repeat: no-repeat;
        background-size: auto;
      
    }
}
.rowWithTransparentBgAnimation {
    @include transparentBg(transparentBgAnimation);

    @include display_md {
        background-repeat: no-repeat !important;
    background-size: contain !important;
    }
    @media only screen and  (min-width: 1201px) and (max-width: 1600px) { 
     background-position-x: center;
    }
}

//a variable shared by  two classes - .rowWithTransparentBgVideo2 and .rowWithTransparentBgEventProdComp
@mixin dataForTransparentBgVideo {
    @include display_max_lg {
        background-position-y: 0 !important;
        
        background-color:none;
        
        padding-bottom: 25% !important;
    }

    @media only screen and  (max-width: 480px){
        padding-bottom: 35% !important;
    }
    @include display_xs {
        min-height: 450px !important;
        margin-bottom: -50% !important;
    }
    @include display_sm {
       
        margin-bottom: -45% !important;
    } 
     @include display_md {
        background-repeat: no-repeat !important;
        background-size: contain !important;
        background-position-y: 76px !important;
        margin-bottom: -30% !important;
    } 
    @include display_lg {
      
        background-position-y: 76px !important;
        margin-bottom: -15% !important;
    } 
    @include display_xl {
      
        padding-top: 10% !important;
        margin-bottom: -10% !important;
    } 
    @media screen  and(min-width: 1200px)and(max-width: 1350px) {
        padding-top: 15% !important;
    }
   
    @media screen  and(min-width: 1351px)and(max-width: 1999px) {
        padding-top: 15% !important;
    }
     @media screen  and(min-width: 2000px)and(max-width: 2300px) {
        padding-top: 12% !important;
    }
}

.rowWithTransparentBgVideo2 {
    @include dataForTransparentBgVideo;
    @include transparentBg(transpatentBgcorporate);

    @include display_max_md {
        background-position-y:0px !important;
    } 
    @include display_md {
        background-repeat: no-repeat !important;
        background-size: contain !important;
        background-position-y:76px !important;
    } 
    @media only screen and  (min-width: 1201px)  { 
        background-repeat: inherit;
        background-size: cover

    }
    @media only screen and (min-width: 1201px) and (max-width: 1438px){
        background-repeat: no-repeat;
        background-size: auto;
      
    }
}

.rowWithTransparentBgEventProdComp {
   
    @include transparentBg(transparentBgEventProdCom);
    @include dataForTransparentBgVideo;
    
    @media only screen and (max-width:1500px) and (min-width: 1301px) {
        margin-bottom: -15% !important;
    }
    @media only screen and (max-width:1300px) and (min-width: 1201px) {
        margin-bottom: -20% !important;
    }
   
}

.rowWithTransparentBgCorporate{
    @include display_xs{
        padding-bottom: 10% !important;
    } 
    @include display_sm{
        padding-bottom: 10% !important;
        margin-top: -35% !important;
    } 
    @include display_md{
        padding-bottom: 20% !important;
        margin-top: -25% !important;
    } 
    @include display_lg{
        padding-bottom: 10% !important;
        margin-top: -10% !important;
    }
     @include display_xl{
        padding-bottom: 10% !important;
        padding-top: 20% !important;
    }
    @media only screen and  (max-width: 410px){
        padding-bottom: 55% !important;
    }
     @media screen  and(min-width: 2051px) {
        padding-top: 15% !important;
        padding-bottom: 5% !important;
    }
    @media screen  and(min-width: 1201px)and(max-width: 1351px) {
        padding-top: 22% !important;
        
    }
    @media screen  and(min-width: 1801px)and(max-width: 2000px) {
        padding-top: 18% !important;
        
    }
   
   
}

// -------------------------------------------

// ---- darkStrip

.darkStripe2 {
    //padding-bottom: 20% !important;
   
    @include display_sm {
        margin-bottom: 0% !important;
    } 
    @include display_md {
        margin-bottom: 0% !important;
    }
    @include display_xs {
        margin-bottom: -20% !important;
       
    } 
    @include display_lg {
        margin-bottom:-10% !important;
       
    } 
    @include display_xl {
        margin-bottom:-5% !important;
        padding-bottom: 10% !important;
    }
   
   
    @media screen and(min-width: 2000px)  {
        padding-bottom: 10% !important;
    }
}


.usability{
    padding: 10% 0 30% 0;

    @include display_max_lg {
        margin-bottom: 10% !important;
    }
    @include display_md {
        margin-top: -15% !important;
    } 
    @include display_sm {
        margin-top: -10% !important;
    } 
     @include display_xs {
        margin-bottom: 0% !important;
    }
}

.monitorCutLeft{
    margin-left: -15% !important;
    width: 100% !important;
    margin-top: -30%;

    @include display_md {
        width: 110% !important;
        
        padding-bottom: 0%;
      
        margin: -30% 0 30% -35% !important;
        
    } 
    @include display_sm {
        width: 110% !important;
       padding-bottom: 20%;
       margin-left: -35% !important;
       margin-top: -30% !important;
    }
     @include display_xs {
        width: 120% !important;
        margin-left: -35% !important;
        
        
    } @include display_xl {
        width: 80% !important;
        margin-left: -15% !important;
        margin-top: -20%;
    }
}

.cyr2 {
    @include display_sm {
        transform: translateY(44%) !important;
    }
}
.wePride{
    @include display_sm {
        padding-top: 35%;
    } 
    @include display_md {
        padding-top: 10%;
    } 
    @include display_lg {
        padding-top: 10%;
    }
}


.row8b{
    @media screen  and(min-width: 1201px)and(max-width: 1400px) {
        margin-top: 10% !important;
      } 
      @media screen  and(min-width: 2201px) {
        margin-top: -5% !important;
      }
}

.introducingSmokeSignal2 {
    padding: 0 2%;
    padding-bottom: 5% !important;

    @include display_xl {
        transform: translateY(60%);
    } 
    @include display_lg {
        margin-top: -5% !important;
    } 
     @include display_md {
        
        margin-top: -10% !important;
        
    } 
    @include display_xs {
        
        margin-top: 10% !important;
    }
    @include display_sm {
        
        padding-bottom: 5% !important;
      
    }
}

.introducingSmokeSignal{
    @include display_xl {
        transform: translateY(40%);
    } 
    @include display_lg {
        margin-top: -50% !important;
    } 
     @include display_md {
        
        margin-top: -10% !important;
        padding: 0 2%;
    } 
    @include display_xs {
        padding: 0 2%;
        margin-top: 10% !important;
    }
    @include display_sm {
        padding: 0 2%;
        padding-bottom: 5% !important;
      
    }

    @media screen  and(min-width: 1201px)and(max-width: 1400px) {
      transform: translateY(50%);
    }
    @include display_max_sm {
        width: 100%;
        button {
            max-width: 100%;
        }
    }

}

.row6b{
    @include display_xl{
        margin-top: -10% !important;
    }
   
       @include display_xl{
      margin-top: -5% !important;
    }
    @include display_max_lg{
      margin-top: -10% !important;
    } 
    @include display_md{
      margin-top: 0% !important;
    } 
     @include display_sm{
      margin-top: 5% !important;
    } 
      @include display_xs{
      margin-top: -25% !important;
    } 
    @include display_lg{
      margin-top: 0% !important;
    }
  
    @media screen  and(min-width: 1401px)and(max-width: 1600px) {
        margin-top: -8% !important;
    }
     @media screen  and(min-width: 1601px)and(max-width: 1800px) {
        margin-top: -10% !important;
    }
    @media screen  and(min-width: 1801px)and(max-width: 2000px) {
        margin-top: -10% !important;
    }
    @media screen  and(min-width: 2001px)and(max-width: 2200px) {
        margin-top: -16% !important;
    }
    @media screen  and(min-width: 2201px) {
        margin-top: -20% !important;
    }
}


.row12-simple {
    margin-top: -20%;

    @include display_md {
        margin-top: -50%;
    }
    @include display_sm {
        margin-top: -50%;
    }
}

.row12 {
   
    margin-top: -15% !important;
    @include display_max_lg {
        margin-top: -50% !important;
    }
    @media screen  and(min-width: 2201px) {
        margin-top: -30% !important;
    }
    @media screen  and(min-width: 1201px)and(max-width: 1800px) {
        margin-top: -5% !important;
    }
}

.row13b-down {
    margin-top: 30% !important;
    @include display_xs {
        margin-top:12% !important ;
    }
    @include display_sm {
        margin-top:8% !important ;
    }
    @include display_md {
        margin-top:8% !important ;
    }
}

.row13 {
    margin-top: -5%;
}

.row14 {
    margin-top: -10% !important;
    padding-right: 15px;
    padding-left: 15px;
     @media screen  and(min-width: 2201px) {
     margin-top: -30% !important;
    }
}

.project-356 {
   

    @include display_md {
        margin-top: 5% !important;
    } 
     @include display_sm {
        margin-top: 5% !important;
        margin-bottom: -3% !important;
    } 
    @include display_xs {
        margin-top: 5% !important;
        margin-bottom: -3% !important;
    }
}

.short-para {
    font-size: 1.2em;
    
    @include display_md {
        width: 90%;
        margin-left: 5%;
    } 
     @include display_sm {
        width: 80%;
        margin-left: 10%;
    } 
     @include display_xs {
        width: 90%;
        margin-left: 5%;
    }
}
//  ------------- creative agency ----

.yourChallenge{
    padding: 30% 0 ;

    @include display_md {
        padding: 0;
        margin-top: -60%;
    }
    @include display_sm {
        padding: 0;
        margin-top: -80%;
    }
    @include display_xs {
        padding-bottom: 20%;
        margin-top: -160%;
       
    } 
    @include display_lg {
       
        margin-top: -35%;
       
    }
}


.at2020{
    padding-top: 0% !important;
    
    @include display_xs {
        margin-top: 20% !important;
        margin-bottom: -5% !important;
    }
}

.diveInto{
   

    @include display_xl {
        width: 100%;
       
    }
      @include display_lg {
        width: 100%;
        margin-left: 0% !important;
    } 
    @include display_md {
       
        width: 600px;
        margin-left: calc(50% - (600px / 2)) !important;
    }
    @include display_sm {
       
        width: 600px;
        margin-left: calc(50% - (600px / 2)) !important;
    } 
    @include display_xs{
       
        width: 112%;
        margin: -5% -30px -5%;
        margin-bottom: -4% !important;
    }
   
    @media only screen and (max-width:1500px) and (min-width: 1201px) {
        margin-left: calc(50% - (500px / 2)) !important;
        width: 500px;
    }
}

// ------ Corporate

.leftColumnAnim, .leftColumnDM, .leftColumnWeb {
 
    @include display_max_lg{
    
        order: 2;
    }
}   

 .rightColumnAnim, .rightColumnDM, .rightColumnWeb {
    @include display_max_lg{
    
        order: 1;
    }
}
// ---------- Animation

.corporateMessage{
    margin-top: -10% !important;

    @include display_lg {
        margin-top: 20% !important;
    } 
    @include display_md {
        margin-top: 12% !important;
    } 
    @include display_sm {
        margin-top: 25% !important;
    }
    @include display_xs {
        margin-top: -15% !important;
    }
}

.rowWithTransparentBgAnimation {
    
    padding-bottom: 6% !important;

      
        @include display_sm {
            margin-top: -35% !important;
        } 
        @include display_md {
            margin-top: 0% !important;
            padding-bottom: 15% !important;
           
        }
         @include display_xs {
            margin-top: -30% !important;
        }

        @media only screen and (min-width: 1600px) { 
            padding-bottom: 5% !important;
        }
    
}

.ofAllTheAnimation{
    @include display_md {
        margin-top: 5% !important; 
        margin-bottom: -5% !important;
    }
    @include display_xs {
        margin-top: 10% !important; 
        margin-bottom: -30% !important;
    }
    @include display_sm {
        margin-top: 5% !important; 
        margin-bottom: -8% !important;
    }
}


// ------------- event management software


.projectExamples{
    @include display_md{
    
        justify-content: center;
    }
}


.gpcaImg{
    
 
  @include display_xs {
    transform: translateY(-50%) !important;
    margin-bottom: -30% !important;
  }
  @include display_sm {
    transform: translateY(-10%) !important;
    margin-bottom: -5% !important;
  }
  @include display_md {
    transform: translateY(-35%) !important;
    margin-bottom: -15% !important;
    
  }
  @include display_xl {
    transform: translateY(70%) !important;
  }
  @include display_lg {
    transform: translateY(95%) !important;
  }


    @media only screen and (max-width:1500px) and (min-width: 1200px) {
        transform: translateY(65%) !important;
    }
}

.prod-img {
    @include display_xs {
        margin-top: 43% !important;
        margin-bottom: -50% !important;
    }
    @include display_sm {
        margin-top: 15% !important;
        margin-bottom: -25% !important;
    } 
    @include display_md {
        margin-top: 5% !important;
        margin-bottom: -13% !important;
    } 
    @include display_lg {
        transform: translateY(-40%);
    } 
    @include display_xl {
        margin-top:4% !important;
        margin-bottom: -5% !important;
    } 
    @media only screen and (min-width: 1801px) {
        margin-bottom: 5% !important;
    }
    transform: translateY(-60%);
}

.leftColumnProdComp {
    @include display_sm {
        margin-top: -25% !important;
    } 
    @include display_md {
        margin-top: -25% !important;
    } 
    @include display_max_lg {
        margin-bottom: 5% !important;
    }
}
.row21 {
    @include display_max_lg{
        padding-bottom: 5% !important;
    }
    @include display_lg{
        margin-top: 20% !important;
    }  
    
    @include display_xl{
        margin-top: 15% !important;
    } 
    @include display_xs{
        margin-top: -10% !important;
       
    }
    @include display_sm{
        margin-top: 30% !important;
    }
    @include display_md{
        margin-top: 30% !important;
        padding-bottom: 15% !important;
    }
}

.eveProdEdi-right {
    @include display_lg {
        margin-top: -20% !important;
    }
    @include display_xl {
        margin-top: -5% !important;
    }
}

.eventsAre {
    @include display_xl {
        margin-top: -20%;
    } 
    @include display_xs {
        padding: 0 15px !important;
    }
}
.multitalented{
   

    @include display_max_md{
        margin-top: 0% !important;
    }

    @include display_xl{
        margin-top: -3%;
    } 
     @include display_lg{
        margin-top: -12%;
    } 
    @include display_md{
        margin-top: -12%;
    }
}


.sf-left-our-approach {
    @include display_xl{
        margin-bottom: 0%;
    } 
  
}


.threeQuotes-left{
margin-top: 50%;
margin-left: 10%;

@include display_xl {
    margin-top: 40% !important;
    width: 50% !important;
    margin-left: 30% !important;
}
@include display_lg {
    margin-top: 40% !important;
    width: 70% !important;
    margin-left: 15% !important;
}

    @include display_md {
        margin-top: -10% !important;
        width: 40% !important;
        margin-left: 30% !important;
        margin-bottom: -10% !important;
       
    } 
   
   
    @include display_sm {
        
        margin: 20% 0 10% 10% !important;
        width: 70% !important;
        background-size: 80%;
        p {
            margin-left: 5%;
            width: 90%;
        }
  
    }
    @include display_xs {
        margin-bottom:22% !important;
        margin-left: 8%;
        margin-top: -20%;
        width: 84% !important;
    }

    @media only screen and (min-width: 1200px)and (max-width: 1500px) {
        margin-top: 70%;
       
    }
    @media only screen and (min-width: 1801px) {
        width: 50% !important;
    }
    
}
.threeQuotes-right{
    
    @include display_xl {
        width: 50% !important;
        margin-left: 20% !important;
       // margin-top: -20% !important;
    }
    
    @include display_lg {
        width: 70% !important;
        margin-left: 5% !important;
       
    }
    @include display_md {
        width: 40% !important;
        margin: 10% 5% ;
    }
    @include display_max_sm {
        margin-left: 10%;
        margin-top: -20%;
    }
    @include display_sm {
        margin-left: 10%;
        width: 70% !important;

        p {
            margin-left: 5%;
            width: 90%;
        }
        
    }
     @include display_xs {
        margin-top: -45%;
        margin-bottom: 25%;
        
    }

    @media only screen and (min-width: 1801px) {
        width: 50% !important;
    }
}

.row13b {
    background-color: $darkBlue;
    color: $white;
    width: 100% !important;
    padding: 10% 10% 0% 10%;


    img {
        width: 90%;
        margin-left: 5%; 
        padding-top: 10%;
       

        @include display_max_sm{
            width: 130%;
            margin-left: -15% !important;
        }
    }

    @include display_max_sm{
        padding-top: 10%;
      
        width: 100% !important;
        
    }
    @include display_md{
        
        margin-bottom: 5%;
       
        
    }
}

.videoIntroForDSSC{
    @media only screen and (min-width: 992px)and (max-width: 1000px)  { 
        margin-bottom: 35% !important;   
    }
    @media only screen and (min-width: 1001px)and (max-width: 1018px)  { 
        margin-bottom: 30% !important;   
    }
    @media only screen and (min-width: 1019px)and (max-width: 1100px)  { 
        margin-bottom: 25%;   
    }
    @include display_lg {
        margin-bottom: 25%;
    }
    @media only screen and (min-width: 1201px)and (max-width: 1300px)  { 
        margin-bottom: 15%;   
    }
}

.row17b {
    margin-bottom: 10%;
}

.badgeContainer{
    @include display_max_lg{
        padding-bottom: 15% !important;
    }
}

.messageColumnSFProductionCompanies {
    margin-top: -20%;

    @include display_md {
        margin-top: -20%;
    }
    @include display_sm {
        margin-top: -30%;
    }
     @include display_xs {
        margin-top: -40%;
    }
    @media only screen and (min-width: 2000px)  {
           
       margin-top: -25%;
    }
}

.quotes3-row {
    @media only screen and (min-width: 992px)and (max-width: 1124px)  { 
        margin-top: 10%;   
    }
    @include display_lg {
        margin-bottom: -5%;
    }
}

.quotes3 {
    @include display_sm {
        margin-left: 10% !important;
        margin-top: -30% !important;
    }
}
.quotes-3 {
    @include display_sm {
       
        margin-top: -52% !important;
    }
    @include display_max_sm {
       
        margin-top: -50% !important;
    }
    @include display_xl {
       
        margin-top: -10% !important;
    }
    @include display_lg {
       
        margin-top: -30% !important;
    }
    @media only screen and (min-width: 2000px)  {
           
        margin-top: -5% !important;
     } 
     @media only screen and (min-width: 1201px)and (max-width: 1500px)  {
           
        margin-top: -15% !important;
     }
}

.ifYou {
    @include display_max_sm {
        padding-top: 30% !important;
    }
}


.quotes3-bottom {
    @include display_md{
        //padding-bottom: 15%;
        margin-top: -20%;
    }
}


.content-start-sub{
    @include display_max_lg {
        margin-top: 0 !important;
    }
}


.ourApproach {
  @include display_max_lg {
      margin-bottom: 5%;
  } 
 
  
}



.rightColumnWeb {
    @include display_xl {
        margin-bottom: 5% !important;
    }
    @include display_lg {
        margin-top: -10% !important;
    }
   
    @include display_sm {
       // margin-top: -10% !important;
    }
    @include display_xs {
        margin-bottom: -5% !important;
    }

}
.leftColumnWeb {

    @include display_sm {
        margin-top: -40% !important;
        & img {
            margin-left: calc(50% - (600px / 2)) !important;
            width: 615px !important;
        }
    }
    @include display_xs {
        margin-top: -70% !important;
    }

}



.sf-event-col{
    
    margin-top: -30%;

    @include display_max_lg {
        margin-top: -30% !important;
    }
}

.row26{
    margin-top: 20% !important;
    @include display_max_lg {
        margin-top: 25% !important;
    }
    @include display_lg {
        margin-top: 25% !important;
    }
}

.row29 {
    @include display_xl {
        margin-top: 3% !important;
    }
    @include display_lg {
        margin-top: 10% !important;
    }
    @include display_md {
        margin-top: 0% !important;
    } 
    @include display_sm {
        margin-top: 15% !important;
    }
   
    
}

.asACreative {
    margin-top: 10%;

    @include display_lg {
        margin-top: 0%;
    }
}

.q-small-left{

    @include display_md {
        padding-top: 0% !important;
    }
     @include display_sm {
        background-size: 80%;
    }
    
}
.q-small-right{

    @include display_md {
        padding-top: 0% !important;
    }
    @include display_sm {
        background-size: 80%;
    }
    
}

.red-btn {
    background-color: #EE555C !important;
    border-color: #EE555C !important;
}

.recentProject {
    transform: translateY(-25%);
    width: 70% !important;
    //height: 500px !important;
    
    @include display_xs{
        transform: translateY(-20%);
    }
    @include display_sm{
        transform: translateY(-20%);
    } 
   
    @include display_lg{
        transform: translateY(-20%);
    }
    @media only screen and (max-width: 350px) {
        transform: translateY(-10%);
    }


    .category-project {
        display: none !important;
    }

    .item {
      
    width: 100%;
    min-height: 500px;
    
    @include display_xs{
        margin-bottom: -10% !important;
    }
   
    }

    img {
         width: 60% !important;
        
         margin-left: 20%;
         -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.5);
        -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.5);
        box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.5);

         @media only screen and (min-width: 1600px) {
            width: 60% !important;
            margin-left: 20% !important;
        }

       @include display_max_md {
           width: 86% !important;
           margin-left: 6% !important;
       }
       @include display_md{
        width: 70% !important;
        margin-left: 15% !important;
    }
    }

    h3 {
        margin-left:15%;
        color: $white;
        font-size: 2.0em;

        @media only screen and (min-width: 1600px) {
           
            margin-left: 20% !important;
        }
        @media only screen and (min-width: 1201px) and (max-width: 1600px) {
           
            margin-left: 20% !important;
        }
        @include display_xs{
            margin-left :  5% !important;
        }
        @include display_sm{
            margin-left :  5% !important;
        }
        @include display_md{
            margin-left :  15% !important;
        }
        @include display_lg{
            margin-left :  20% !important;
        }
    }

    a {
        margin-left: 15%;
        @media only screen and (min-width: 1601px) {
           
            margin-left: 20% !important;
        } 
        @media only screen and (min-width: 1201px) and (max-width: 1600px) {
           
            margin-left: 20% !important;
        }
        @include display_xs{
            margin-left :  5% !important;
        }
         @include display_sm{
            margin-left :  5% !important;
        } 
         @include display_md{
            margin-left :  15% !important;
        }
         @include display_lg{
            margin-left :  20% !important;
        }
    }
}

.goAbove {

    padding-bottom: 20% !important;
}

.messageBoxes-forProduction  {
    @include display_md {
        transform: translateY(10%);
    }
}


.row23-leftCol {
    @include display_xl {
        margin-top: -2%;
        padding-bottom: 10%;
    }
    @media only screen  and (min-width: 1900px) {
        margin-top: -5%;
    }
    @media only screen and (max-width:1300px) and (min-width: 1201px) {
        padding-bottom: 12%;
    }
    @include display_lg {
        margin-top: -2%;
        padding-bottom: 15%;
    }
    @include display_md {
        margin-top: -8%;
        padding-bottom: 20%;
    }
    @include display_sm {
        margin-top: -8%;
        padding-bottom: 28%;
    }
    @include display_xs {
        margin-top: -8%;
        padding-bottom: 20%;
        margin-left: -15px !important;
        margin-right: -15px !important;
    }
}

.row22 {
    @include display_xl {
        margin-top: -10%;
    }  
    @include display_lg {
        margin-top: -10%;
    }
}

.row28{
    @include display_xs {
        margin-top: 40% !important;
    }
    @include display_sm {
        margin-top: 28% !important;
    }
    @include display_md {
        margin-top: 15% !important;
    } 
    @include display_xl {
        margin-top: -5% !important;
    }
}
.row6 {
   @include display_max_lg {
    margin-top: 20%;
   } 
   @include display_lg {
    margin-top: 5%;
   } 
    @include display_xl {
    margin-top: -5%;
   } 
    @include display_xs {
    margin-top: 35%;
   } 
   @include display_sm {
    margin-top: 25%;
   } 
}

.sf-event-message {
    @include display_lg {
        margin-top: 13%;
       } 
       @include display_max_md {
        margin-bottom: 15%;
       } 
}

.prod-message {
    @include display_sm {
        margin-top: 7% !important;
    }
}
.pushMessage {
    @include display_md {
        margin-top: -10%;
       } 
       @include display_sm {
        margin-top: -15%;
       }
       @include display_xs {
        margin-top: -15%;
       } 
   
}
.row6d {
    margin-top: -5%;
}

.cyr-img {
    @include display_xl {
        width: 100%;

    }
      @include display_lg {
        width: 100%;
    } 
    @include display_md {
       
        width: 600px !important;
        margin-left: calc(50% - (600px / 2)) !important;
    }
    @include display_sm {
       
        width: 600px !important;
        margin-left: calc(50% - (600px / 2)) !important;
        
    } 
    @include display_xs{
       
        width: 112% ;
        margin: 35% -15px 10% -15px;
    }

    @media only screen and (max-width:1500px) and (min-width: 1201px) {
        margin-left: calc(50% - (500px / 2)) !important;
        width: 500px;
    }
}

.usab-digit {
    @media screen  and(min-width: 1190px) {
        margin-top: 20% !important;
    }
}


.row4 {
    

    @include display_xl {
       margin-top: -5%;
    }
    @include display_lg {
       margin-top: 5%;
    }
    @media screen  and(min-width: 1201px)  and (max-width: 1900px) {
        margin-top: 1%;
    }
}

.row13c {
    @include display_lg {
        margin-top: -1%;
        margin-bottom: 5%;
    } 
   
}


.proudOf2 {
    @include display_lg {
        margin-top: 30%;
      
    }
     @include display_md {
        margin-top: -5%;
        margin-bottom: 10%;
      
    }
      @include display_xs {
        margin-top: 10%;
        margin-bottom: 20%;
     
    }
}

.sf-1-quote {
   
    @include display_xl {
        margin-top: 9% !important;   
    }
    @include display_max_md {
        margin-top: 9% !important;   
    }
    @include display_xs {
        margin-top: -10% !important;   
        margin-bottom: 0% !important;
    }
    @media screen  and(min-width: 1201px)  and  (max-width: 1300px){
        margin-top: 25% !important;
        }
}
.row6c {
   margin-top: -8%;

   @media screen  and(min-width: 1501px)  and  (max-width: 1800px){
    margin-top: -7% ;
    }
}

// .v2 {
//     margin-left: 20px !important;
// }

.row2b {
    @include display_xl {
        margin-top: -10%;
    }
  
}
.row2c {
    
    @include display_md {
        margin-top: 5% !important;
    }
    @include display_sm {
        margin-top: 10% !important;
    }
    @include display_xs {
        margin-top: 10% !important;
    }
}

.laptop4 {
   
    
    @include display_md {
        margin-top: 5% !important; 
    }
    @include display_sm {
        margin-top: 5% !important; 
    }
    @include display_xs {
        margin-top: 7% !important; 
        margin-bottom: 0% !important;
    }
   
}

.expandMessage2 {
    @include display_xl {
        margin-top: 0% !important;
    }
    @media screen  and(min-width: 1661px)  and  (max-width: 1750px){
        margin-top: 1% !important ;
    } 
    @media screen  and(min-width: 1751px)  and  (max-width: 1900px){
        margin-top: 2% !important ;
    } 
     @media screen  and(min-width: 1901px)  and  (max-width: 2100px){
        margin-top: 3% !important ;
    } 
    @media screen  and(min-width: 2101px)  and  (max-width: 2180px){
        margin-top: 4% !important ;
    }
    @media screen  and(min-width: 2181px)  and  (max-width: 2300px){
        margin-top: 5% !important ;
    } 
    @media screen  and(min-width: 2301px) {
        margin-top: 6% !important ;
    }
    
}
.inno5 {
    @include display_xl {
        transform: translateY(-30%);
    } 
    @include display_lg {
        transform: translateY(-35%);
    } 
    @include display_md {
        transform: translateY(-35%);
        margin-bottom: -0% !important;

    }
    @include display_sm {
        transform: translateY(-40%);
        margin-top: 10% !important;
        margin-bottom: -14% !important;
     
    }
    @include display_xs {
        transform: translateY(-45%);
        margin-left: -30px !important;
        margin-bottom: -37% !important;
    }
}

.rightColumnDSCP .inno2 {

    

    @include display_xl {
        transform: translateY(-40%);
    } 
    @include display_lg {
        transform: translateY(-35%);
    } 
    @include display_md {
        transform: translateY(-35%);
        margin-bottom: -7% !important;

    }
    @include display_sm {
        transform: translateY(-40%);
        margin-top: 10% !important;
        margin-bottom: -8% !important;
     
    }
    @include display_xs {
        transform: translateY(-45%);
        margin-left: -30px !important;
        margin-bottom: -37% !important;
    }
    
}

.inno3 {
    @include display_xs{
       
        margin-bottom: -42% !important;
        margin-top: 20% !important;
    }
    @media only screen and  (min-width: 1501px) {
        margin-top: 10% !important;
    }
    @include display_sm {
        margin-bottom: -20% !important;
    }
    @include display_md {
        margin-bottom: -25% !important;
    }
}

.col-1-digit {
    
    @include display_xs {
        margin: 20% 0 -30% 0;
    } 
     @include display_sm {
        margin: -10% 0 -5% 0;
    } 
    @include display_md {
        margin: 2% 0 -5% 0;
    }
   
}

.digit-message {
    @include display_md {
        margin-top:-5% !important;
    }
}


.vert-event-1 {
    max-height: 300px;
    margin-bottom: 20px !important;
} 
.vert-event-2 {
        max-height: 400px;
        margin-bottom: 20px !important;
}
.vert-event-3 {
        max-height: 200px;
        margin-bottom: 20px !important;
}

.vert-prod-1 {
    max-height: 200px;
    margin-bottom: 20px !important;
}
.vert-prod-2 {
    max-height: 150px;
    margin-bottom: 20px !important;
}
.vert-prod-3 {
    max-height: 150px;
    margin-bottom: 20px !important;
}

.vert-sf-event-1 {
    max-height: 250px;
    margin-bottom: 20px !important;
    margin-left: 20px !important;

    @include display_md {
        margin-left: 15px !important;
    }

}
.vert-sf-event-2 {
    max-height: 200px;
    margin-bottom: 20px !important;
    margin-left: 15px !important;

    @include display_md {
        margin-left: 14px !important;
    }
}
.vert-sf-event-3 {
    max-height: 200px;
    margin-bottom: 20px !important;
    margin-left: 20px !important;

    @include display_md {
        margin-left: 15px !important;
    }
}

.vert-sf-prod-1 {
    max-height: 280px;
    margin-bottom: 20px !important;
}
.vert-sf-prod-2 {
    max-height: 250px;
    margin-bottom: 20px !important;

    @include display_sm {
        margin-left: 17px !important;
    }
}
.vert-sf-prod-3 {
    max-height: 200px;
    margin-bottom: 20px !important;
}
.vert-sf-prod-4 {
    max-height: 130px;
    margin-bottom: 20px !important;
}

.vert-video-1 {
    max-height: 180px;
    margin-bottom: 20px !important;

    @include display_sm {
        margin-left: 16px !important;
    }
    @include display_lg {
        margin-left: 16px !important;
    }
}
.vert-video-2 {
    max-height: 330px;
    margin-bottom: 20px !important;

    @include display_sm {
        margin-left: 16px !important;
    }
    @include display_lg {
        margin-left: 16px !important;
    }
}
.vert-video-3 {
    max-height: 230px;
    margin-bottom: 20px !important;

    @include display_sm {
        margin-left: 16px !important;
    } 
    @include display_lg {
        margin-left: 16px !important;
    }
}

.web-quote {
    @include display_max_md{
        margin: -10% 0% -25% !important;
    } 
    @include display_lg {
        margin-top: 30% !important;
    }
   
}

// .container {

//     @include display_xs {
//         padding-left: 0px !important;
//         padding-right: 0px !important;
//     }
    

// }

.tweet-col {
    @include display_xs {
        margin: -40% 0%;
    }
}

.oracle-img {
    @include display_xs {
        margin-bottom: 0% !important;
        margin-top: 28%;
    }
}

.onTweeter {
    @include display_sm {
        transform: translateY(35%);
    }
    @include display_md {
        transform: translateY(45%);
    }
    @include display_lg {
        transform: translateY(115%);
    }
    @include display_xl {
        transform: translateY(65%)
    }
    @media only screen and  (min-width: 1201px)and  (max-width: 1500px) {
        
       transform: translateY(75%);
    }
}
.small-margins {
    @include display_max_md {
        padding: 0 15px;
    }
    
}

.video-prod-message {
    @include display_max_md {
        margin-top: 5% !important;
    }
}