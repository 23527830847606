$brightBlue : #24CED1;
$coral:  #EE555C;
$navy: #204367;
$navyTrans: rgba(32,67,103,0.7);
$teal: #24CFD2;
$darkBlue: #1F2536;
$darkerBlue: #00162C;
$paleGrey: rgba(239, 239, 239, 0.9);
$coolGrey: #B2BFCF;
$grey: #4A4A4A;
$navgrey: #9B9B9B;
$white: #FFFFFF;
$buttonWidth: 300px;

//Fonts

@mixin proxNorm {
  font-family: proxima-nova, Arial, Helvetica, sans-serif !important;
  font-weight: 400;
  font-style: normal;
  src: url("../assets/fonts/ProximaNova-Regular.otf") format("otf"), url("https://use.typekit.net/ajz4gto.css");
  font-display: swap;
}
@mixin proxBold {
  font-family: proxima-nova, Arial, Helvetica, sans-serif;
  font-weight: 700;
  font-style: normal;
  src: url("../assets/fonts/ProximaNova-Bold.otf") format("otf"), url("https://use.typekit.net/ajz4gto.css");
  font-display: swap;
}
@mixin proxExBold {
  font-family: proxima-nova, Arial, Helvetica, sans-serif;
  font-weight: 800;
  font-style: normal;
  src: url("../assets/fonts/ProximaNova-Bold.otf") format("otf"), url("https://use.typekit.net/ajz4gto.css");
  font-display: swap;
  
}

//Animations
