.webMessage {
    padding-top: 20%;
}
.superTest {
    display:block;
}

.someOfOur-HeadingWeb {
    @include display_xs {
        margin-bottom: 75px; 
    } 
     @include display_sm {
        margin-top: 35%;  
    } 
    @include display_md {
        margin-top: 20%;
    }
    @include display_lg {
        margin-top: 13%;
    }
    @include display_xl {
        margin-top: 5%;
       
    }

    @media only screen and (max-width: 1400px) and (min-width: 1200px) {  
        margin-top: 12%;
    }
}

.sitemap-col {
    @include display_xs {
        margin-top: 16px !important;
    }
}