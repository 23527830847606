@mixin imgWidth($width: 100%){
    width: $width;
    
}
@mixin marginBottom( $margin-bottom: -50%){
    margin-bottom: $margin-bottom;
    
}
@mixin prodChain ($width: 100%) {
  img {
      width: $width;
      margin-top: -6%;
  }
}

#videoIframe {
    display:none;
}

.introColumn{
    display: flex;
    align-items: center;
    font-size: 1.2em;
}

.flexColumn{
    display:flex;
}
.iconFilm{
    align-self: flex-end;
    margin: 0 auto;
}
.boxImageFilmContainer{  
    justify-content: space-around;
    // background-color: $paleGrey;
    position: relative;
    top:-200px;
}
.recentFilmProjects{
    margin-top: -50%;
    width: 100%;
}
.centerColumnContent{
     display: flex;
    flex-direction: row;
    justify-content: center;
}

.darkBackgroundContainerFilm {
    background-color: $darkBlue;
    min-height: 520px;
    position: relative;
}

.productionChain{

  flex-direction: row;
  justify-content: center;
  
  img{
      margin-top: -10%;
      display: block;
      width: auto;
  }
   
}
.filmMessage{
    color: $darkerBlue;
    font-size: 2.5rem;    
}

.verticalProjectChain {
    flex-direction: row;
    justify-content: center;
    img {
        display: none;
    }
}

@media only screen and (max-width: 1400px) and (min-width: 992px) {
    .recentFilmProjects{
        @include imgWidth(100%);
        @include marginBottom(-40%);
        
    }
    .theTeamContainer{
        padding: 15% 0 0 0;
    }
    .productionChain{
        @include prodChain(70%);
    }
}

@media only screen and (max-width: 991px) and (min-width: 768px) {
    .productionChain{
        @include prodChain(80%);
    }
}

@media screen and (max-width: 767px) {
    .recentFilmProjects{
        @include imgWidth(80%);
        @include marginBottom(60%);
    }
    .productionChain{
        @include prodChain(80%);
    }

}
@media screen and (max-width: 600px) {
   
    .productionChain{
        img {
            display: none;
        }
    }
    .verticalProjectChain {
        padding-bottom: 20% !important;
        img {
            display: block;
            margin-top: -45%;
            height: 100%;
            
        }
        
    }
    .darkBackgroundContainerFilm{
        padding-bottom: 27%;

        @media screen and (max-width: 400px) { 
            padding-bottom: 45%;
        }
       
    }

}

