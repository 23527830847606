.about{
  background-image: url('../assets/images/contact_img_camera.png');
  display: block;
  width: 100%;
}
html.webp.no-jpeg2000 {
  .about{
    background-image: url('../assets/images/webp/contact_img_camera.webp');
   
  }
}
html.jpeg2000.no-webp{
  .about{
    background-image: url('../assets/images/jp2/contact_img_camera.jp2');
  }
}




#playMainVideo{
  position: absolute;
  z-index: 100;
  top: 45%;
  color: $white;
  cursor: pointer;
  outline: none;

  &:hover {
    color: $teal;
    outline: none;
  }
}
#hpVideoFull{
  display: none;
}

.cookieInfo{
  
    @include display_max_sm {
    margin: 0 auto;
    }
}
.cookieConsent {
    -webkit-box-shadow: 0px -1px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px -1px 5px 0px rgba(0,0,0,0.75);
    box-shadow: 0px -1px 5px 0px rgba(0,0,0,0.75);
    @include display_max_sm {
    display: grid !important;
    grid-template-columns: repeat(1, 1fr);
    text-align: center;
    }
}

.declineButton {
  border-radius: 4px !important;
 
}



.whatwedo-intro{
  width:100%;
  color:$navy;
  display: flex;
  align-items: flex-start;

  @include display_max_md {
    margin: 0 -30px 0 -30px !important;
    min-width:110%;
    display: table;
  }

  .whatwedo-description{

    
    align-items: top;
    
    @include display_max_md {
      margin: 0 30px 0 -30px !important;
      min-width:100%;
      display: table;
    }

    h1{
      font-size:3.2em
    }
    
    h2,h3{
      font-size:1.5em;
      @include proxBold;
      color: $teal;
      text-transform: uppercase;
    }

    p{
      color: $grey;
      font-size:1.4em;
    }

   
  }
.intro-home {
  margin-left: 6% !important;
  width: 90%;
  @include display_xl {
    margin-left: 0% !important;
   
  }
  @include display_sm {
    margin-left: 10% !important;
  } 
  @include display_xs {
    margin-left: 5% !important;
  }
}

.main-intro {
  @include display_xs {
    margin-left: -1% !important;
    h1 {
      margin-left: 5% !important;
    }
  }
  @include display_xl {
    margin-left: 0% !important;
    h1 {
      margin-left: 0% !important;
    }
  }
}

  .art-intro-right {
    @include display_max_md {
      order: 1;
    }
  }
  .art-intro-left {
    @include display_max_md {
      order: 2;
    }
  }

  @include display_sm {
    // margin-top: -5%;
  }
  @include display_xs {
    // margin-top: -5%;
  }
}
.services{
  a{
      @include proxBold;
      text-transform: uppercase;
      color:$teal;
      margin: 25px 0;
      display: block;
      
      &.btn-primary {
        color: $white !important;
        display: inline-block;

        &:hover{
          background-color: $teal;
          border-color: $teal;
          text-decoration: none;
          -webkit-box-shadow: 0px 0px 10px 0px rgba(32,67,103,0.45);
          -moz-box-shadow: 0px 0px 10px 0px rgba(32,67,103,0.45);
          box-shadow: 0px 0px 10px 0px rgba(32,67,103,0.45);
        }
        &:active, &:focus {
          background-color: $teal !important;
          border-color: $teal !important;
          -webkit-box-shadow: inset 0px 0px 10px 0px rgba(32,67,103,0.45) !important;
          -moz-box-shadow: inset 0px 0px 10px 0px rgba(32,67,103,0.45) !important;
          box-shadow: inset 0px 0px 10px 0px rgba(32,67,103,0.45) !important;
          outline: none; 
        }
      }
    }

    .table td, .table th {
      border-top: unset;
      color: #828C98;
    }

   
}


.bottom-service-home {
    @include display_max_md {
      margin-bottom: -15% !important;
    }
  
}


.grey-box{
  background-color: $paleGrey;
}


.brief {
  @include display_xs {
    .upper {
      padding-top: 5% !important;
    }
  }
}
.sectionNavy {
  background-color: $darkBlue;
  //margin-top: 200px;
  padding-bottom: 100px;
  font-size: 1.2rem;

  .img-offset-up {
    position: relative;
    margin-top:100px;

    &.home{
      margin-top: -100px;
      @include display_xs {
        margin-top:0;
      }
      @include display_sm {
        margin-top:0;
      }
    }
    @include display_xs {
        margin-top:10%;
    }
    @include display_sm {
      margin-top:10%;
    }
  }
  .img-offset-down {
    position: relative;
    margin-top:100px;
    @include display_xs {
      margin-top:20%;
    }
    @include display_sm {
      margin-top:20%;
    }
  }
  .body-offset{
    margin-top: 100px;
  }

  a {
    background-color: $coral;
    border-color: $coral;
    color: $white;
    margin-top: -30px;
    margin-left: 20px;
    position: relative;
    z-index: 2;
  

    &.btn-primary {
      color: $white !important;
    }
    &:hover, &:active, &:focus{
      background-color: $white !important;
      border-color: $white !important;
      color: $coral !important;
      text-decoration: none;
    }
  }
  span.badge {
    background-color: $white;
    color: $coral;
    text-transform: uppercase;
    padding: 2% 2.5%;
    margin: 25px 0;
  }

  .hr-divider{
    border-top: 1px solid white; 
    margin-top: 10%;
    opacity: 0.3;
  
    @include display_sm {
      margin-top: 20%;
    } 
    @include display_xs {
      margin-top: 20%;
    }
  }

}


.startPlayingHome {
  transition: .5s;
  width: 70px;
  height: 70px;
  background: transparent url("../assets/images/icons/svg/iconPlay-01.svg") no-repeat;
  

  animation: 2s appearance;
  
  animation-fill-mode: backwards;
  animation-delay: 1s;


  //animation: 2s fadeInText;

  @include display_xs{
    width: 50px !important;
    height: 50px !important;
    animation: 2s appearance;
  }

  &:hover {
    transition: .5s;
    transform: scale(1.2);
    cursor: pointer;
    background: transparent url("../assets/images/icons/svg/iconPlay-01-Rev-01.svg") no-repeat;
}

}

.playIconHomePage {
  position: absolute;
  z-index: 100;
  top: 47% !important;
  left: calc(50% - 35px);
  

  &:hover {
    visibility: visible;
    transition: .5s;
    transform: scale(1.2);
    cursor: pointer;
    background: url("../assets/images/icons/svg/iconPlay-01-Rev-01.svg") no-repeat;
}
  

  @include display_md {
    top: 42% !important;
  }
  @include display_sm {
    top: 40% !important;
  
  }
   @include display_xs{
    top: 38% !important;
    left: calc(50% - 25px);
    
  }


}.closeIconHomePage {
  position: absolute;
  animation: 1s fadeInText;
  animation-fill-mode: backwards;
  animation-delay: 2s;
  z-index: 100;
  top: 47% !important;
  color:#24CFD2;
  right: 1% !important;
  top: 10% !important;
  

  @include display_lg {
    top: 11% !important;
  }
  @include display_md {
    top: 13% !important;
  }
  @include display_sm {
    top: 3% !important;
  }
   @include display_xs{
    top: 2% !important;
    
  }
}

a.services-btn {
 padding-left: 15%;
 padding-right: 15%;

 @include display_xs{
   display: block !important;
   font-size: 0.9rem;
 }
}

.brief {
  margin-top: 100px;

  

   @include display_sm {
      margin-top:10%;
      margin-bottom: 20%;
      &.home {
        margin-top: 0;
        margin-bottom: 0;
      }
    } 
    @include display_xs {
      margin-top:-10%;
      margin-bottom: 20%;
    }
}


.trs {

@include display_max_md {
      margin-bottom: 20%;
  }

  &.home {
    margin-bottom: 0;;
  }
}

.img-1 {
  margin-left: -15px !important;
  width: 100% !important;
}

.whatwedo-intro2 {
  @include display_max_md {
    display: flex !important;
    flex-direction: column;
  }
}
.home-intro-col-left {
  display: flex;
justify-content: flex-end;
}