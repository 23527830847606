
  
  .profileImg{
    max-width: 100px !important;
    border-radius: 50% !important;
  }
  


#articles{

  .showAuthor{
    .profileImg{
      max-width: 80px;
      border-radius: 50%;
    }
    
  }

  .card{
    -webkit-box-shadow: 0px 2px 14px 0px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px 2px 14px 0px rgba(0,0,0,0.2);
    box-shadow: 0px 2px 14px 0px rgba(0,0,0,0.2);
    min-height:530px;
    border: none;
    
    &:hover{
      -webkit-box-shadow: 0px 2px 18px 0px rgba(0,0,0,0.4);
      -moz-box-shadow: 0px 2px 18px 0px rgba(0,0,0,0.4);
      box-shadow: 0px 2px 18px 0px rgba(0,0,0,0.4);
    }

    .card-title{
      @include proxExBold;
      min-height: 60px;
      text-transform: uppercase;
    }
    .card-footer{
      background-color: $white;
      border-top: none;
    }

    @include display_md {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }

  .btn-primary {
    &.active{
      background-color: $coral;
      border-color: $coral;
    }
  }
}

article#posts{
  h1{
    font-size:1.5em;
    @include proxBold;
    color: $coral;
    text-transform: uppercase;
  }

  .articleBody {
    img {
      max-width:100%;
      height: auto;
      display: block;
      margin: 10px auto !important;
    }
    iframe {
      max-width:100%;
      display: block;
      margin: 25px auto !important;
    }
  }
}
.projects {
  box-sizing: border-box;
  
  .item{
    height: 268px;
    overflow: hidden;
    position: relative;
    
    &.hovered {
      .workItemHover{
        display: flex;
       
      }
    }

    .workItem{
      position: absolute;
      z-index: 1;
      display: flex;
      width: 100%;
      height:auto;

      @include display_md {
        width: auto !important;
        height: 100%;
        transform: translateX(-50%);
        margin-left: 50%;
        max-width: unset;
      }
       @include display_lg {
        max-width: fit-content;
        width: 120%;
        transform: translateX(-50%);
        margin-left: 50%;
      }
     
    }
    .workItemHover{
      position: absolute;
      z-index: 2;
      display: none;
      width: 100%;
      height: 268px;
      padding: 1rem;
      text-align: center;
      justify-content: center;
      background-color: $navyTrans;
      flex-wrap: wrap!important;
      cursor: pointer;
      align-self: center;
      

      h3, p {
        color: $white;
        text-transform: uppercase;
        display: block;
        width: 100%;
        align-items: center;
      }
      
      .btn-primary {
        z-index: 10;
        display: block;
        align-items: center;
        height: fit-content;
        max-height: 60px;
      }


      //RR styling (display_md)
    @include display_md {
       margin-top: -10%;
      padding-bottom: 130% !important;
    }
     
    }
  }
}

.projects .lgWork .item  .workItem {
  @include display_md {
     height: 129%;
  }

}

#project {
  h1 {
    @include proxBold;
    text-transform: uppercase;
    font-size: 2rem;
      @include display_max_lg{
        font-size: 1.5rem;
      }
      @include display_sm{
        margin-top: 40px;
      }
      @include display_xs{
        margin-top: 20px;
      }
  }
  .small{
    line-height: 1.6rem;
  }
  .divide {
    border-right: 2px solid $coolGrey;
    @include display_max_md{
      border: none;
    }
  }
  .singleCitationPink{
    p{
      text-align: center;
      padding: 30% 0;
      @include display_max_lg{
        padding: 10% 0;
      }
      @include display_max_md{
        padding: 30% 0;
      }
    }
    @include display_max_md{
      margin-bottom: 0;
    }
  }
  .scrollParent {
    position: relative;
    
    .scrollMore {
      position: absolute;
      top: 2%;
      color: $teal;
      text-align: center;
      width: 100%;
      .svg-inline--fa{
        font-size: 2.5rem;
      }
    }  
  }
}

.cardImg {
  height: 250px;
  display: none;
    -webkit-filter: blur(4px);
  -moz-filter: blur(4px);
  -ms-filter: blur(4px);
  -o-filter: blur(4px);
  filter: blur(4px);
  
 

    .card-img-top {
      height: inherit;
      object-fit: cover;
      
   
        @include display_sm {
        //object-fit: contain;

        width: 50%;
        border-top-left-radius: calc(.25rem - 1px);
        border-top-right-radius: calc(.25rem - 1px);
        position: absolute;
        top: 0;
        left: 25%;
       }


    }

    @include display_md {
      height: 218px !important;
    } 
    @include display_sm {
      display: flex;
      position: relative;
    } 
    @include display_xs {
      display: flex;
      position: relative;
      
    }
}
.card {
  height: 630px;

  @include display_md {
    height: 100%;
  }

  @include display_xs {
    height: auto;
  }
}


// Blurry card image ---------

.card-img-top {
  height: 250px;
  object-fit: cover;
  
  
  
  @include display_max_md {
    //object-fit: contain;

    width: 50%;
    border-top-left-radius: calc(.25rem - 1px);
    border-top-right-radius: calc(.25rem - 1px);
    position: absolute;
    top: 0;
    left: 25%;
    border: 1px solid #f1f1f1;
    max-height: 220px;
    margin-top: 10px;
    
  
   }
   
  @include display_xs {
    max-height: 230px;   
    width: inherit; 
    left: 17%;
    border: 1px solid #f1f1f1;
    max-height: 220px; 
    top: 5px;
    left: 50%;
    transform: translate(-50%, 0%);
  }





  @include display_xl {
     
    height: 286px;  
   
  }  
  
   @include display_md {
    height: 218px !important;
    height: auto;   
    
  }  
  
}

.card-deck .card {
  margin-right: 0px;
    margin-bottom: 0;
    margin-left: 0px;
}
// ---     -----------------------------------------


// Article Pagination

.pagination {
  width: 25%;
  height: 100%;
  cursor: pointer;

  .page-item{
    list-style-type: none !important;
    font-size: 20px;

    a{
      color: $coolGrey !important;
      outline-style:none !important;
      outline-color: transparent;
      &:hover{
        cursor: pointer;
      }
      &:focus {
        outline-style:none !important;
        outline-color: transparent;
      }
    }
    
    &.active {
      a{
        background-color: $coral !important;
        border-color: $coral !important;
        color: white !important;
        outline-style:none !important;
        outline-color: transparent;
        &:focus {
          outline-style:none !important;
          outline-color: transparent;
        }
      }
    }
  }
  .nextBtn, .previousBtn {
    list-style-type: none !important;
    margin: 0 20px;
    min-width: 20%;
    font-size: 24px;
  
    a{
      text-decoration: none !important;
      cursor: pointer;
      color: $coral;
    }
    &.disabled {
      a{
        color: $coolGrey;
      }
      
    }
  }
}


// -----------------------------------------


.article-intro{
  @include display_xs {
    margin-top: -1% !important;
  } 
  @include display_sm {
    margin-top: -1% !important;
  }
  @include display_md {
    margin-top: 85px !important;
  }
}

.article-description {
  padding-left: 10%;

  @include display_sm {
   
    padding-left: 10% !important;
  }

  @include display_md {
    padding-left: 5% !important;

    margin-top: -40px !important;
  } 
  @include display_lg {
    padding-left: 5% !important;

    margin-top: -40px !important;
  }
}
.article-description-col {
  @include display_xs {
    max-width: 100% !important;
    padding-left: 10%;
  }
    @include display_md {
    max-width: 100% !important;
    padding-left: 0%;
  } 
  @include display_lg {
    max-width: 90% !important;
  } 
  @include display_xl {
    max-width: 100% !important;
  }
  @media only screen and (max-width: 400px)  {
    padding-left: 15%;
  }
  @media only screen and  (min-width: 2001px) {
    margin-left: 23% !important;
    width: auto;
  }

}

.lead {
  margin-top: 1%;
   
}

.article-img-main-right {

  @include display_max_xl {
    min-width: 105% !important;
  } 
  @include display_max_md {
    max-width: fit-content;
  }

}

//RR - styling of individual projects


.row1-article  {

  @include display_xs {

  }
   @include display_sm {
    
  } 
  @include display_md {
    margin-bottom: 0%;
  } 
  @include display_lg {
    margin-bottom: -20%;
  } 
  @include display_xl {
    margin-bottom: -18%;
  }
 
}


.row2-article  {

  .quoteArticle {
    
      width: 80% !important;

      @include display_xs {
        margin: -25% 0% -40% 10% !important;
        height: auto;
        
      }
       @include display_sm {
        margin: -30% 0% -40% 10% !important;
        height: auto;
      } 
      @include display_md {
      margin-left: 5% !important;
      margin-bottom: 0 !important;
      
        width: 90% !important;
        padding: 0 !important;
        p {
          font-size: 1rem;
        }
        
      } 
      @include display_lg {
        margin-top: 2.5% !important;
        margin-left: 10% !important;
      }
       @include display_xl {
        margin-left: 10% !important;
        
      } 
      @media only screen and (max-width: 575px) and (min-width: 465px) {
        width: 70% !important;
        margin-left: 15% !important;
      }
     
    
  }

  @include display_xs {

  }
   @include display_sm {
    
  } 
  @include display_md {
    //margin-bottom: -5%;
    margin-top: 0% !important;
  } 
  @include display_lg {
    margin-bottom: -20%;
  } 
  @include display_xl {
    margin-bottom: -18%;
  }
 
}


.content-start-sub-gap-article{
 
  @include display_md {
    margin-top: 200px;
  } 
  @include display_lg {
    margin-top: 200px;
  } 
  @include display_xl {
    margin-top: 200px;
  }
}

.intro-to-project-img {
height: 100% !important;
}

.center-con {

  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
  
}

// ----------------------- animation --------- single project down arrow

.round {
  position: absolute;
  border: 2px solid $coral;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  border-color: $coral;
  top: -30px;
  background-color: $coral;
  @include display_lg {
    top: -2.7%;
  }
   @include display_md {
    top: -25px;
  } 
   @include display_max_md {
    display: none;
  }

}

#cta{
  width:100%;  position: absolute;margin-top: -25%;
}

#cta .arrow{left: 23%; }
.arrow {position: absolute; bottom: 0;  margin-left:-.5px; width: 12px; height: 12px; background-size: contain; top:15px; }
.bottomArrow{margin-top: 12px;}
.next {color: white;
//background-image: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiI+PHN0eWxlPi5zdDB7ZmlsbDojZmZmfTwvc3R5bGU+PHBhdGggY2xhc3M9InN0MCIgZD0iTTMxOS4xIDIxN2MyMC4yIDIwLjIgMTkuOSA1My4yLS42IDczLjdzLTUzLjUgMjAuOC03My43LjZsLTE5MC0xOTBjLTIwLjEtMjAuMi0xOS44LTUzLjIuNy03My43UzEwOSA2LjggMTI5LjEgMjdsMTkwIDE5MHoiLz48cGF0aCBjbGFzcz0ic3QwIiBkPSJNMzE5LjEgMjkwLjVjMjAuMi0yMC4yIDE5LjktNTMuMi0uNi03My43cy01My41LTIwLjgtNzMuNy0uNmwtMTkwIDE5MGMtMjAuMiAyMC4yLTE5LjkgNTMuMi42IDczLjdzNTMuNSAyMC44IDczLjcuNmwxOTAtMTkweiIvPjwvc3ZnPg==);
}

@keyframes bounceAlpha {
0% {opacity: 1; transform: translateY(0px) scale(1);}
25%{opacity: 0; transform:translateY(10px) scale(0.9);}
26%{opacity: 0; transform:translateY(-10px) scale(0.9);}
55% {opacity: 1; transform: translateY(0px) scale(1);}
}

.bounceAlpha {
  animation-name: bounceAlpha;
  animation-duration:1.4s;
  animation-iteration-count:infinite;
  animation-timing-function:linear;
}

.arrow.topArrow.bounceAlpha {
  animation-name: bounceAlpha;
  animation-duration:1.4s;
  animation-delay:0.2s;
  animation-iteration-count:infinite;
  animation-timing-function:linear;
}

.round .arrow{
  animation-name: bounceAlpha;
  animation-duration:1.4s;
  animation-iteration-count:infinite;
  animation-timing-function:linear;
}
.round .arrow.topArrow{
  animation-name: bounceAlpha;
  animation-duration:1.4s;
  animation-delay:0.2s;
  animation-iteration-count:infinite;
  animation-timing-function:linear;
}
// ----------------------------------------------------------

.showAuthor p {
  @include display_lg{
    padding-left: 12% !important;
  }
}



.img-fluid-row2{
  width: inherit !important;
}

.main-row-single-article {
  @include display_md {
    margin-top: -8% !important;
  }
}