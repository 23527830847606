$xs: 0;
$sm: 576px;
$sm_lt: 575px;
$md: 768px;
$md_lt: 767px;
$lg: 992px;
$lg_lt: 991px;
$xl: 1200px;
$xl_lt: 1199px;

@mixin display_xs {
  @media (min-width: #{$xs}) and (max-width: #{$sm_lt}) {
    @content;
  }
}


@mixin display_sm {
	@media (min-width: #{$sm}) and (max-width: #{$md_lt}) {
    @content;
  }
}
@mixin display_max_sm {
	@media screen and (max-width: #{$sm_lt}) {
    @content;
  }
}


@mixin display_md {
  @media (min-width: #{$md}) and (max-width: #{$lg_lt}) {
   	@content;
  }
}
@mixin display_max_md {
  @media screen and (max-width: #{$md_lt}) {
   	@content;
  }
}

@mixin display_lg {
	 @media (min-width: #{$lg}) and (max-width: #{$xl}) {
		 @content;
	 }
}
@mixin display_max_lg {
	 @media screen and (max-width: #{$lg_lt}) {
		 @content;
	 }
}

@mixin display_xl {
	 @media (min-width: #{$xl}) {
		 @content;
	 }
}
@mixin display_max_xl {
	 @media screen and (max-width: #{$xl_lt}) {
		 @content;
	 }
}