
.staff{
  @include display_max_md{
    .mt-5{
      margin-top: 0 !important;
    }
  }
}
.teamProfile {
  background-image: url('../assets/images/team_GS-No-Gradient.png');
  background-size: cover;
  display: block;
  height: 350px;
  position: relative;
  cursor: pointer;

  &:hover {
    .profileLink {
      border: 3px solid $teal;
      h4{
        color:$coral;
      }
      p{
        color:$teal;
      }
      .svg-inline--fa{
        color:$teal;
      }
    }
  }
  @include display_max_md{
    margin: 0 auto 30px;
    width: 95%;
    height: 285px;
  }
  @include display_md{
    height: 200px;
  }
  @include display_max_sm{
    height: 350px;
  }
  @include display_sm{
    height: 400px;
  }
  @include display_xs{
    height: 300px;
  }
}

html.webp.no-jpeg2000 {
  .teamProfile {
    background-image: url('../assets/images/webp/team_GS-No-Gradient.webp');
  }
}
html.jpeg2000.no-webp{
  .teamProfile {
    background-image: url('../assets/images/jp2/team_GS-No-Gradient.jp2');
  }
}


.teamQuote {
  border-bottom: 10px solid $teal;
  height: 350px;
  color: $navy;
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  
  h3{
    font-size: 2.5rem;
    margin-top: 0;
    @include display_md{
      font-size: 1.5rem;
    }
    @include display_xs{
      font-size: 2rem;
    }
  }

  @include display_max_md{
    margin: 0 auto 50px;
    width: 90%;
    height: 285px;
  }
  @include display_md{
    height: 200px;
  }
}
.socialLink {
  position: absolute;
  bottom: 22%;
  left: 5%;
  text-transform: uppercase;
  a{
    &:hover{
      color: $white;
      text-decoration: none !important;
    }
  }
  @include display_max_md{
    position: relative;
    bottom:0;
    padding-top: 15px;
    margin-bottom: 0;
    
  }
}
.profileLink {
  position: absolute;
  bottom: 5%;
  left: 5%;
  border: 3px solid $white;
  width: 70%;
  padding: 1.5% 3%;


  h4 {
    font-size:1.1rem;
    @include proxBold;
    color: $teal;
    text-transform: uppercase;
    margin-bottom: 0;
    @include display_md{
      font-size:0.9rem;
    }
  }
  p{
    color: $white;
    margin-bottom: 0;
    @include display_md{
      font-size:0.9rem;
    }
  }
  .svg-inline--fa{
    color: $white;
    font-size: 1.8rem;
    margin-top: 6px;
    @include display_md{
      font-size:1.4rem;
    }
  }

  @include display_md{
    width: 90%;
  }
}
.jobSection {
  border-bottom: 10px solid $teal;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(74,74,74,1);
  -moz-box-shadow: 0px 0px 5px 0px rgba(74,74,74,1);
  box-shadow: 0px 0px 5px 0px rgba(74,74,74,1);

  .svg-inline--fa{
    color: $teal;
    font-size: 2.3rem;
    margin: 0 auto 10px;
    display: block;
  }

  .fa-angle-right {
    display: inline;
    font-size: 1rem;
    margin: 0;
  }
}
.full_teal {
  .profileLink{
    @include display_max_md{
      position: relative;
      bottom:0;
      margin-top: -30px;
    }
    @include display_md{
      width: 70%;
    }
  }
  .modal-content {
    background-color: $teal;
    
    .modal-body{
      padding:0;
      color: $white;

      .modal-header{
        .close {
          border-color: $white !important;
          color: $white;
          margin-right: 0.1rem;
        }
      } 
    }
    .divider {
      border-left: 3px solid $white;


      @include display_max_md{
        border-left: none;
        border-top: 3px solid $white;
        margin: auto 5%;
        padding-top: 25px;
      }
    }

    .nav-tabs {
      border-bottom: none;
      margin-bottom: 20px;
      margin-top: 0;
      padding-top: 0;

      .nav-link {
        border:none;
        font-size: 1.5rem;
        color: $white;
        font-weight: 500;
        padding-left:0;
        margin-left:0;
        margin-top: 0;
        padding-top: 0;
        opacity: 0.5;

        &.active {
          background-color: $teal;
          opacity: 1;
         
        }

        &:hover {
          opacity: 1;
          color: $white;
        }
      }
    }
  }
}

ul.moreArticles {
  padding-left: 0;
  &.white {
    li {
      list-style: none;
      padding-left: 0;
      
  
      a, a:link {
        font-weight: 500 ;
        color: $teal;
  
        &:hover{
            color: $coral;
            text-decoration: none;
        }
      }
    }
  }
  &.teal {
    li {
      list-style: none;
      padding-left: 0;
      margin-bottom: 1rem;
  
      a, a:link {
        font-weight: 500 ;
  
        &:hover{
            color: $white;
            text-decoration: none;
        }
      }
      
    }

  }
}


.messageBox2{
  width:120%;
  border: 1px;
  padding: 25px 25px 40px 25px;
  background-color: $white;
  margin: 10% 10px;
  

  -webkit-box-shadow: 2px 3px 8px $grey;
  -moz-box-shadow: 2px 3px 8px $grey;
  box-shadow: 2px 3px 8px $grey;

  @include display_md {
      width:140%;
      margin-left: -10px;
      margin-right: 10%;
  }
  @include display_lg{
      width:120%;
  }
  @include display_sm{
      margin-left: 0%;
      margin-right: 5% !important;
      width: 100%;
  }
  @include display_max_sm{
    margin-left: 0%;
    margin-right: 5% !important;
    width: 100%;
     
  }
 
      img {
          max-width: 55px;
          margin-left: 5px !important;
          padding-right: 10px;
          
          @include display_max_sm {
              margin-bottom: 10px !important;
              
          }

      }

      .verticalTextForAboutHands  {
        max-height: 190px;
      }
      .verticalTextForAboutBriefcase {
        max-height: 190px;
      }
}

.verticalTextForAboutHands {
  margin-left: 10px !important;
}
.verticalTextForAboutBriefcase {
  margin-left: 7px !important;

  @include display_sm {
    margin-left: 10px !important;
  }
}

.close-modal-cda {
  @include display_xs {
    .close {
      margin-left: -50px !important;
      margin-right: 90% !important;
    }
  }
}