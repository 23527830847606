

.coralHeading{
    color: $coral;
    text-transform: uppercase;
}

.darkBGcontainer {
    background-color: $darkBlue;
    min-height: 600px;

    Button{
        margin-top: 5%;
        width: $buttonWidth;
    }
       
}
.theTeamContainer{
    background-color: $paleGrey;
}

.servicesRow{
    display: flex;
    flex-direction: row;
    padding: 1% 0 20% 0;
    transition: 1s ease-in-out;


    h3 {
        text-transform: uppercase;
    }
    @include display_max_md {
        padding-bottom: 25% !important;
    }
}

.digitalRow{
    display: flex;
    flex-direction: row;
    padding: 10% 0;
    margin-top:-10%;

}

.projectsRow{
    display: flex;
    flex-direction: row;
    padding: 5% 0;
}

.whiteTextParagraph{
    color: white;
}

.headingDigital{
    margin-top: 1.5rem;
}

.monitorTilt{
    margin: -30% 0 0 0;
   
  float:right;
}
.delegateManagerHeading{
    margin-top: 2rem;
}

.imgPhoneTilt{
    max-width: 100%;
    margin-top: -80%;
    padding-bottom: 10%;


}

// ----------------------------------------------------------------
// setting different sizes of images on different sizes of screens


@media only screen and(min-width: 1800px) {
    .servicesMessage{
        margin-bottom: 30%;
    }
    .darkBGcontainer{
        padding-bottom: 10%;
    }
    .monitorTilt{
        width: 40%;
        margin-top: -15%;
     }
     .delegateManagerHeading{
         margin-top: 25%;
     }
}
@media only screen and (max-width: 1800px) and (min-width: 1400px) {
    .servicesMessage{
        margin-bottom: 30%;
    }
    .monitorTilt{
        width: 40%;
        margin-top: -15%;
       
     }
     .delegateManagerHeading{
        margin-top: 15%;
    }
}
@media only screen and (max-width: 1400px) and (min-width: 1200px) {
    .monitorTilt{
        width: 40%;
        margin-top: -15%;
     }
    
}
@media only screen and (max-width: 1199px) and (min-width: 1024px) {
    .servicesMessage{
        margin-bottom: 30%;
    }
    .darkBGcontainer{
        padding-bottom: 0%;
    }
    .monitorTilt{
        width: 40%;
        margin-top: -15%;
     }
}

@media only screen and (max-width: 1024px) and (min-width: 992px) {
    .imgPhoneTilt{
        max-width: 90%;
        margin-top: -80%
    }
    .servicesMessage{
        margin-bottom: 30%;
    }
    .monitorTilt{
       width: 50%;
       margin-top: -15%;
    }
    .darkBGcontainer{
        padding-bottom: 10%;
    }
}

@media only screen and (max-width: 991px) and (min-width: 768px) {
    .imgPhoneTilt{
        max-width: 80%;
        margin-top: -70%
    }
    .monitorTilt{
        width: 50%;
        margin-top: -15%
    }
    .servicesMessage{
        margin-bottom: 30%;
    }
    .darkBGcontainer{
        padding-bottom: 15%;
    }
}

@media only screen and (max-width: 767px) and (min-width: 601px) {
    .imgPhoneTilt{
        max-width: 60%;
        margin: -50% auto 0 20%;
    }
    .servicesMessage{
        margin-bottom: 30%;
    }
    .monitorTilt{
        width: 40%;
        margin-top: -15%;
    }
    h5 {
        margin-top: 3%;
    }
    .darkBGcontainer{
        padding-bottom: 10%;
    }
}
@media screen and (max-width: 600px) {
    .imgPhoneTilt{
        display: block;
        max-width: 60%;
        margin: -50% auto 0 auto;
    }
    .monitorTilt{
        width:40%;
        margin-top: -15%;
    }
    .servicesMessage{
        margin-bottom: 40%;
    }
    .darkBGcontainer{
        padding-bottom: 20%;
    }
}

.playbackIcon{
   

    &.closeVideo{
        right: 2%;
        top: -1%;
        padding-top:0;
        position: absolute;
        animation: 1s fadeInText;
        animation-fill-mode: backwards;
        animation-delay: 2s;
        cursor: pointer;
        
        

        @include display_lg {
           position: absolute;
          top:-2%;

        } 
        @include display_md {
           position: absolute;
          top: 10.5%;

        }
         @include display_sm {
           position: absolute;
           top: -4%;
           right: 2%;

        }
           @include display_xs {
           position: absolute;
           top: -5%;
            right: 2%;
        }

        @media screen and (min-width: 1550px)  and (max-width: 2050px){ 
            top: 0% !important;
        }
        @media screen and  (min-width: 2051px){ 
            top: 1% !important;
        }
    }
    svg {
        width: 30px !important;

        @media screen and  (min-width: 1751px){ 
            width: 34px !important;
        }
    }
}

.solutions {
  
    @include display_max_md {
        padding-bottom: 50% !important;
    }
}


.someOfOur-HeadingDesign{
    @include display_md {

    margin-top: 20% !important;
    }
    // @include display_sm {

    // margin-top: 20% !important;
    // margin-bottom: -200px;
    // }

    @media only screen and (max-width: 767px) and (min-width: 601px) { 
        margin-top: 30% !important;
    }

}

.someOfOur-HeadingFilm {
    margin-top: 6% !important;

    @include display_md {

        margin-top: 18% !important;
        }
        @include display_sm {
    
        margin-top: 20% !important;
        margin-bottom: -200px;
        }
        @include display_xs {
    
        margin-top: 5% !important;
        margin-bottom: 25%;
        }
        @media only screen and (max-width: 600px) and (min-width: 577px) {
            margin-bottom: 20%;
        }
        @media only screen and (max-width: 380px) {
            margin-bottom: 50%;
        }
}

.smallVertical{
    @media only screen and (max-width: 600px) and (min-width: 577px) {
        margin-bottom: 1%;
    }
    @media only screen and (max-width: 380px) {
        margin-bottom: 10%;
    }
}

.iconPlayOnSmall {
    
    @include display_md {
        
        width:40px;
        position: absolute;
        right: calc(50% - 20px);
        top: 34%;
        transform:translateX(25%); 
        
    } 
    
    @include display_sm {
        
        width:30px;
        position: absolute;
        right: calc(50% - 10px);
        top: 22%;
        transform:translateX(25%); 
        
    } 
    
    @include display_xs {
        
        width:30px;
        position: absolute;
        right: 50%;
        top: 90px;
        transform:translateX(25%); 
        
    }

   
}

@media only screen and (max-width: 319px) and (min-width: 0px) { 
    .iconPlayOnSmall { 
        width:20px;
        position: absolute;
        right: 50%;
        top: 50px;
        transform:translateX(25%);
    }
}



.centralPlay {
    align-self:center;  
}

.weAre2020{
    max-width: 90%;
    margin-left: 10%;
    @include display_md {
        font-size: 2.4em !important;
    }
    @include display_max_sm {
        font-size: 2.6em !important;
        
    } 

    @media only screen and (max-width: 385px) { 
        font-size: 2.3em !important;
    }
}

  @keyframes appearance {
      0% {transform: scale(0);  opacity: 1;}
      50% { transform: scale( 1.3);}
      100% {transform:  scale( 1); opacity: 1; }
      
  }

// ---------------------------------------- play icon

@mixin playIconData {
   // align-items: center;
  
    &:hover {
        visibility: visible;
        transition: .5s;
        transform: scale(1.2);
        cursor: pointer;
        background: url("../assets/images/icons/svg/iconPlay-01-Rev-01.svg") no-repeat;
    }
    
    // remove these two for 2 sec animation
        // animation-delay: 2s;
        // visibility: hidden;
    // ---------------------

    z-index: 1020;
    align-self:center;
    position: absolute;
    margin-top: 11%;
    margin-left: calc(50% - 35px);
    width: 70px;
    height: 70px;
    transition: .5s;
    background: transparent url("../assets/images/icons/svg/iconPlay-01.svg") no-repeat;
    outline: none !important;

    @include display_md {
        margin-top: 85px;
    }
     @include display_sm {
        margin-top: calc( 135px - 270%);
       
    } 
    @include display_xs {
       // margin-top: 18px;
        width: 50px;
        height: 50px;
        margin-left: 0px;
        display: flex;
       
        
       
    align-items: center;
    }
    @media only screen and (width: 768px) {
        margin-top: 70px;
    }
    @media only screen and (max-width: 910px) and (min-width: 769px) {
        margin-top: 70px;
    }
    @media only screen and (max-width: 1550px) and (min-width: 992px) {
        margin-top: 10%;
    }
    @media only screen and (min-width: 1551px) and (max-width: 2000px) {
        margin-top: 11%;
    }  
  
    @media only screen and (min-width: 576px) and (max-width: 680px) {
        margin-top: 130%;
    } 
    @media only screen and (min-width: 470px) and (max-width: 575px) {
        margin-top: 30%;
    }
    @media only screen and (max-width: 469px) and (min-width: 450px) {
        margin-top: 10%
    }
    @media only screen and (max-width: 449px) and (min-width: 420px) {
        margin-top: -20%
    } 
    @media only screen and (max-width: 419px) and (min-width: 400px) {
        margin-top: -12px;
    } 
    @media only screen and (max-width: 399px) and (min-width: 380px) {
        margin-top: -12px;
    } 
    @media only screen and (max-width: 379px) and (min-width: 350px) {
        margin-top: -20px;
    }
    @media only screen and (max-width: 349px) and (min-width: 300px) {
        margin-top: -30px !important;
    }
  
    
  
}

.startPlay0 {
    @include playIconData;
    animation: 2s appearance;
    animation-fill-mode: backwards;
    animation-delay: 0s;
    
    
}

.startPlay {
    @include playIconData;
    animation: 2s appearance;
    animation-fill-mode: backwards;
    animation-delay: 2s;
    
    
    
    
    @include display_max_lg {
        animation-delay: 0s;
    }
}


// -------------------------------------------------------------

h3 {
    margin-top: 50px;
}

.btn-block {
    margin-bottom: 20px !important;
}

.mainHeading {
    //margin-bottom: 15%;
    padding-bottom: 10%;

    @include display_max_sm {
        margin-bottom: 20%;
    }
}

.darkDigital {
    margin-bottom: 40%;
}

.tealHeading {
    color: $teal;
    font-size: 2.5em;
    margin-bottom: 10%;
}

.workItemHover h3{
    @include display_md {
        font-size: 18px !important;
    }
}

#videoPoster{
    max-height: 100%;
}

.boxImageFilmContainerVert {
    @include display_max_md {
        margin-top: -134px;
    }
}

