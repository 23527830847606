

.expandVideo{
    background-color: black;
    transition: width 2s;
    width: 100% !important;
}

.shrinkVideo{

    background-color: black; 
    transition: width 2s;
    width: 50% !important;

    @media screen and (min-width: 992px) { 
        float: left;
    }
}

@mixin mainMessageData {

    width: 36%;
    float: right;
 
    // position: sticky;
    top: 7%;
    left: 52% !important;


    @include display_max_lg{
        display: none;
      
    }

    //----- --- ---------
    @include display_lg {
        // height: 300px;
        // margin-bottom: 10% !important;
    }
    @media only screen and (max-width: 1300px) and (min-width: 1201px) {
        height: 300px;
        margin-top: calc(31% - 380px) !important;
        margin-bottom: 10% !important;
    }
     @media only screen and (max-width: 1400px) and (min-width: 1301px) {
        height: 300px;
        margin-top: calc(30% - 400px) !important;
        margin-bottom: 10% !important;
    }  
       @media only screen and (max-width: 1500px) and (min-width: 1401px) {
        height: 300px;
        margin-top: calc(30% - 400px) !important;
        margin-bottom: 10% !important;
    }   
 
       @media only screen and (max-width: 1600px) and (min-width: 1501px) {
        height: 300px;
        margin-top: calc(29% - 400px) !important;
        margin-bottom: 10% !important;
    }  
       @media only screen and (max-width: 1800px) and (min-width: 1601px) {
        height: 300px;
        margin-top: calc(26% - 400px) !important;
        margin-bottom: 10% !important;
    } 
    
    @media only screen and  (min-width: 1801px) and (max-width: 2000px){
        height: 400px;
        margin-top: calc(25% - 400px) !important;
   
    } 
    @media only screen and  (min-width: 2001px) and (max-width: 2200px){
        height: 400px;
        margin-top: calc(24% - 400px) !important;
   
    } 
    @media only screen and  (min-width: 2201px) and (max-width: 2500px){
        height: 400px;
        margin-top: calc(23% - 400px) !important;
   
    }
    @media only screen and  (min-width: 2501px) and (max-width: 2700px){
        height: 400px;
        margin-top: calc(22% - 400px) !important;
   
    }
    
    @media only screen and  (min-width: 2701px) {
        height: 400px;
        margin-top: calc(22% - 400px) !important;

    }
}

.expandMessage{

    @include mainMessageData;
   position: absolute;

    
    @include display_lg { 
        animation: 2s fadeInText;
        animation-fill-mode: forwards;
        animation-delay: 2s;
        visibility: hidden;
        margin-top: 20px !important;
       // margin-left: calc(5% + 35px) !important;
        top: 2%;    
    }
    
    @include display_xl {
        animation: 2s fadeInText;
        animation-fill-mode: forwards;
        animation-delay: 2s;
        visibility: hidden;
        //margin-left: calc(5% + 35px) !important;
    
        top: 6%;
    } 
  
}


.expandMessage0 {
@include mainMessageData;
position: absolute;
    @include display_lg { 
        animation: 2s fadeInText;
        animation-fill-mode: forwards;
        animation-delay: 0s;
        visibility: hidden;
        margin-top: 20px !important;
       // margin-left: calc(5% + 35px) !important;
        top: 2%;

    }
    @include display_xl {
        animation: 2s fadeInText;
        animation-fill-mode: forwards;
        animation-delay: 0s;
        visibility: hidden;
        //margin-left: calc(5% + 35px) !important;

        top: 6%;
    }
}


.hideMessage{
    position: absolute;
    //display: none;
    visibility: hidden;
    max-height: 500px;

    @include display_max_lg {
        display: none;
    }
}

@keyframes fadeInText {
    
    0% {
        opacity: 0;     
      }
      100% {
        visibility: visible;
        opacity: 1;
      }
}

  @media screen and (max-width: 991px) {
    .expandVideo {
        width: 100%;
    }
    .shrinkVideo{
        width: 100% !important;
    }
   
  }



  .videoIntroContainer {

  position: relative;
  height: 100%;
  display: flex;
  min-width: fit-content;
  transition: 1s ease-in-out;
  

    @media screen and (min-width: 992px) and (max-width: 1440px){    
        display: inline-block;
        max-width: 103%;
        margin-bottom: 8%;
        width: 103%; 
        min-height: 300px;
    } 
    @media screen and (min-width: 1441px) and (max-width: 1940px){   
        display: inline-block;
        width: 102%; 
        margin-bottom: 5%;
        min-height: 380px;
    }
    @media screen and (min-width: 1941px) {   
        display: flow-root;
        margin-bottom: 5%;
        min-height: 380px;
    }
  }

  .shrinkVideo {
    @media screen and (max-width: 991px) and (min-width: 768px) { 
          margin-top: 75px !important;         
      }
       @media screen and (max-width: 767px) and (min-width: 576px) { 
          margin-top: 0px !important;        
      }
  } 
  .expandVideo {
      
      @include display_md {
          margin-top: 75px !important;
          
      }
  }

  .singleProject{
      @include display_max_md {
          margin-top: -50px !important;
      }
  }

 