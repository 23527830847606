

form.contactForm{
  

  .form-group {
      margin-bottom: 0.6rem;
  }

  @include display_xl{
    margin-top: 2.1rem;
  }
  @include display_lg{
    margin-top: -2.1rem;
  }
  @include display_md{
    margin-top: 2.1rem;
  }
  @include display_sm{
    margin-top: 2.1rem;
  }
}

.clockBox {
  font-size: 1.2em;
  position: absolute;
  width: inherit;
  bottom: 1%;
  color: $white;
  text-align: center;
  width: 100%;
  transform: translate(0, -100%);
 
  @include display_max_md{
    bottom: 0;
  }
}



//all needs to be cascaded into display_max_md
.locationImages{
  display: flex;
  position: relative;

  @media only screen and  (min-width: 768px) {
    margin-top: 100px;
  }

  @include display_max_md{
    order: 1;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    align-self: center;
  }

  .box1, .box2, .box3 {
    width: 33.33%;
    height: 624px;
    background: url("../assets/images/loc_edinburgh.png") no-repeat;
    background-size: cover;
    background-position-x: right;
    cursor: pointer;
  }

  html.webp.no-jpeg2000 {
    .box1, .box2, .box3 {
    
      background: url("../assets/images/webp/loc_edinburgh.webp") no-repeat;
      
    }
  }
  html.jpeg2000.no-webp{
    .box1, .box2, .box3  {
      background: url("../assets/images/jp2/loc_edinburgh.jp2") no-repeat;
    }
  }

  .box1 {
    transition: transform .2s;
    position: relative;
    z-index: 1;
    margin: 0 auto;
    //transition: all .2s ease-in-out;

    @include display_max_md{
      min-width: 80%;
      max-height: 424px;
      align-self: center;
      object-fit: cover;
      background: url("../assets/images/loc-Edi-large.png") no-repeat;
      background-position: center;
    }

    &:hover{
      z-index: 5;
      .clockBox{
          display: none;
        }
      .overlayMap1{
        opacity: 1;
        z-index: 2;
        position: absolute;  
        transform: scale(1.1); 
      }

      .overlayBox1{
        //transform: scale(1.1); 
        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+1,000000+20,000000+100&0+0,0.8+35 */
        background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.02) 1%, rgba(0,0,0,0.46) 20%, rgba(0,0,0,0.8) 35%, rgba(0,0,0,0.8) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.02) 1%,rgba(0,0,0,0.46) 20%,rgba(0,0,0,0.8) 35%,rgba(0,0,0,0.8) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.02) 1%,rgba(0,0,0,0.46) 20%,rgba(0,0,0,0.8) 35%,rgba(0,0,0,0.8) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#cc000000',GradientType=0 ); /* IE6-9 */
        position: absolute;
        bottom:0;
        z-index: 3;
        display: block;
        width: 100%;
        height: 57%;
        transform: scale(1.1); 
        bottom: -3%;
        
        @include display_max_md{
          display: none;
        }
      }
      
    
    }

    .overlayBox1{
      display:none;
      @include display_max_md{
        height: 50% !important;
        position: absolute;
      }
    }
    .overlayMap1{
        
      @include display_sm{
         display: none;   
      }     
      @include display_xs{
         display: none;   
      }     
    }
  }

  html.webp.no-jpeg2000 {
    
    .box1 {
     
      @include display_max_md{
       
        background: url("../assets/images/webp/loc-Edi-large.webp") no-repeat;
       
      }
     
    }
  }
  html.jpeg2000.no-webp{

    .box1 {
  
      @include display_max_md{

        background: url("../assets/images/jp2/loc-Edi-large.jp2") no-repeat;
      }

    }
  }
  
// -----box2 --------

  .box2 {
    background: url("../assets/images/loc_sanfran.png") no-repeat;
    background-position-x: right;
    position: relative;
    z-index: 1;
    margin: 0 auto;
    transition: transform .2s;

    @include display_max_md{
      min-width: 80%;
      max-height: 424px;
      align-self: center;
      object-fit: cover;
      background: url("../assets/images/loc-SF-large.png") no-repeat;    
      background-position: center;
    }

    &:hover{
      z-index: 5;
      .clockBox{
          display: none;
        }

      .overlayMap2{
        opacity: 1;
        z-index: 2;
        position: absolute;   
        transform: scale(1.1);   
      }

      .overlayBox2{
        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+1,000000+20,000000+100&0+0,0.8+35 */
        background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.02) 1%, rgba(0,0,0,0.46) 20%, rgba(0,0,0,0.8) 35%, rgba(0,0,0,0.8) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.02) 1%,rgba(0,0,0,0.46) 20%,rgba(0,0,0,0.8) 35%,rgba(0,0,0,0.8) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.02) 1%,rgba(0,0,0,0.46) 20%,rgba(0,0,0,0.8) 35%,rgba(0,0,0,0.8) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#cc000000',GradientType=0 ); /* IE6-9 */
        position: absolute;
        bottom:0;
        z-index: 3;
        display: block;
        width: 100%;
        height:53%;
        transform: scale(1.1); 
        bottom: -3%;

        @include display_max_md{
          display: none;
        }
      }
     
    }

    .overlayBox2{
      display:none;
      @include display_max_md{
        // width: 70% !important;
        // margin: 0 auto;
        height: 50% !important;
        position: absolute;
      }
    }
    .overlayMap2{
      @include display_sm{
        display: none;   
     }     
     @include display_xs{
        display: none;   
     } 
    }
    
  }

  html.webp.no-jpeg2000 {
    
    .box2 {
      background: url("../assets/images/webp/loc_sanfran.webp") no-repeat;
  
        @include display_max_md{
        
          background: url("../assets/images/webp/loc-SF-large.webp") no-repeat;    
      
        }
      
    }
  }
  html.jpeg2000.no-webp{

    .box2 {
      background: url("../assets/images/jp2/loc_sanfran.jp2") no-repeat;
    
        @include display_max_md{
      
          background: url("../assets/images/jp2/loc-SF-large.jp2") no-repeat;    

        }
  
      }
  
  }

// --------- box 3 -----------

  .box3 {
    background: url("../assets/images/loc_dubai.png") no-repeat;
    background-position-x: right;
    position: relative;
    z-index: 1;
    margin: 0 auto;
    transition: transform .2s;
    display: flex;

    @include display_max_md{
      min-width: 80%;
      max-height: 424px;
      padding: 0;
      background: url("../assets/images/loc-Dubai-large.png") no-repeat;
      background-position: center;

      .overlayBox3 {
        display: none;
      }

    }

  

    &:hover{
      z-index: 5;
      .clockBox{
        display: none;
      }
      .overlayMap3{
        opacity: 1;
        z-index: 2;
        position: absolute;  
        transform: scale(1.1);    
      }

      .overlayBox3{

        background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.02) 1%, rgba(0,0,0,0.46) 20%, rgba(0,0,0,0.8) 35%, rgba(0,0,0,0.8) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.02) 1%,rgba(0,0,0,0.46) 20%,rgba(0,0,0,0.8) 35%,rgba(0,0,0,0.8) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.02) 1%,rgba(0,0,0,0.46) 20%,rgba(0,0,0,0.8) 35%,rgba(0,0,0,0.8) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#cc000000',GradientType=0 ); /* IE6-9 */
        transform: scale(1.1); 
        position: absolute;
        bottom:0;
        z-index: 3;
        display: block;
        width: 100%;
        height:50%;
        bottom: -3%;

        @include display_max_md{
          display: none;
        }
      }
      
    }

    .overlayBox3{
      display:none; 

        @include display_max_md{
          display: none;
        }
    }
    .overlayMap3{
      @include display_sm{
        display: none;   
     }     
     @include display_xs{
        display: none;   
     } 
    }
  }
  

  html.webp.no-jpeg2000 {
    
    .box3 {
      background: url("../assets/images/webp/loc_dubai.webp") no-repeat;
     
      @include display_max_md{
      
        background: url("../assets/images/webp/loc-Dubai-large.webp") no-repeat;

      }

    }
  }
  html.jpeg2000.no-webp{

    .box3 {
      background: url("../assets/images/jp2/loc_dubai.jp2") no-repeat;
  
        @include display_max_md{
        
          background: url("../assets/images/jp2/loc-Dubai-large.jp2") no-repeat;
    
        }
  
    }
  
  }


  .overlayMap1, .overlayMap2, .overlayMap3 {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
  }

}
//end of cascade

.leftColumn{
  @include display_max_md{
    order: 2;
    margin-top: 60px;
  }
}


.closeMap{
  display: none;
}
.none{
  display: none;
}


.rollBack {
  position: absolute;
  transition: right 5s ease-out;
  will-change: transform;
  transform: translate3d(-0%, 0 ,0);
  
  left: 100%;
  width: 200%;
  z-index: 6;
  background-color: transparent;
  will-change: transition;

  .overlayBoxForSFC{
    display: none;
  }
}

.expand {
  position: absolute;
  
  left: -100%;
  width: 200%;
  z-index: 6;
 

  //transition: all 1s ease-in-out;
  
  @include display_xl {
    width:200%;
    left: -100%;
  }
  @include display_md {
    width:200%;
    left: -100%; 
  }
  @include display_sm {
    width:100%;
    left: 0%;
    max-height: 424px;
    
    

  }
  @include display_xs {
    width:96%;
    left: 2%;
  
  }


  .overlayBoxForSFC{
      
    background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.02) 1%, rgba(0,0,0,0.46) 20%, rgba(0,0,0,0.8) 35%, rgba(0,0,0,0.8) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.02) 1%,rgba(0,0,0,0.46) 20%,rgba(0,0,0,0.8) 35%,rgba(0,0,0,0.8) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.02) 1%,rgba(0,0,0,0.46) 20%,rgba(0,0,0,0.8) 35%,rgba(0,0,0,0.8) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#cc000000',GradientType=0 ); /* IE6-9 */
    
    position: absolute;
    bottom:0;
    z-index: 3;
    display: block;
    height:100%;
    padding-top: 23%;
    
    width: 200px;

    @include display_lg {
      padding-top: 28%;
    }
    @include display_md {
      padding-top: 55%;
    }
    @include display_sm {
      padding-top: 15%;
      top: -424px;
    }
    @include display_xs {
     display: none;
    }
}
}

.closeMap{
  display: block;
  z-index: 7;
  position: absolute;
  top: 5px;
  right: 7px;

  @include display_sm {
    top: -424px;
  }
  @include display_xs {
    top: -424px;
  }


}



.awards {
  @include display_max_xl {
    width: 80%;
    margin-left: 10%;
  }
  @include display_md {
    display: none;
  }
}

.contactDetails {
  @include display_md {
    margin-bottom: 0 !important;
  }
}
.divider {
  @include display_md {
    display:none;
  }
}

.map {
 
    width: 100% !important;

    @include display_sm {
      max-height: 424px;
      top: -424px;
      
     }
    @include display_xs {
      max-height: 424px;
      top: -424px;
      
     }
  
}

.animateMap-enter {
  opacity: 0.01;
  width: 0%;
  
}

.animateMap-enter.animateMap-enter-active {
  opacity: 1;
  width: 200%;
  transition: all 3000ms;

  @include display_max_md {
    width: 100%;
  }

}

.animateMap-leave {
  opacity: 1;
}

.animateMap-leave.animateMap-leave-active {
  opacity: 0.01;
  transition: 2000ms;
}



.contactRow {
  margin-top: 100px;

  @include display_md{
    margin-top: 60px;
  }
  @include display_sm{
    margin-top: 20px;
  }
  @include display_xs{
    margin-top: 20px;
  }
}

.contactAll {
  margin-top: 3rem!important;

  @media only screen and (max-width: 767px) {
    margin-top: 0rem!important;
  }

  h1 {
    @include display_lg{
      margin-top: -10% !important;
      margin-bottom: 10% !important;
    }
    @include display_md{
      margin-top: 0% !important;
      margin-bottom: 10% !important;
      width: 200%;
    }
    @include display_sm{
      margin-top: 2.1rem;
    }
  }
 
}