
.navbar-nav{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 60px;
    justify-content: flex-end;
    align-items: center;
    z-index:999;
    @include proxBold;

    list-style: none !important;

    a{
        text-decoration: none;
        color: $navy;

        &:hover, &.active{
            color: $teal;
        }
    }

    @include display_sm {
        display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    height: 100%;
    }
}



.navbar-brand {
    display: inherit;
    font-size: inherit;

    &:hover{
        text-decoration: none !important;
    }
}

.nav-item{
    text-align: center;
    width:auto;
    word-wrap: unset;
    margin-left: 1.5%;
    margin-right: 1.5%;
    text-decoration:none;
    @include proxExBold;

    .contact-link{
        border:1px solid;
        border-radius:5px;
        padding:5px 10px;

        &:hover, &.active{
            background-color: $teal;
            border-color: $teal;
            color: $white !important;
        }
    }
}

.mainNavbar {
    transition: .6s !important;
    @include proxNorm;
    position: fixed;
    

    &.navbar-dark {
         background-color: rgba(93, 93, 93, 0.7) !important;
        //background-color:rgba(31, 37, 54, .6);
        background-image: linear-gradient(rgba(31, 37, 54, .6), rgba(31, 37, 54, .4)) !important;
        transition: .6s !important;

        .navbar-nav{
            .nav-link{
                color:$white;
                text-decoration:none;
                &.active,&:hover {
                    color: $teal;
                }
                &.contact-link{
                    &:hover, &.active{
                        color: $white !important;
                    }
                }
            }
        } 
        #logoAlt{
            display: none;
        }
        #logo{
            display: block;
        }
        .companyName{
            color: $white !important;
            text-transform: uppercase;
            margin-top: 8px;
            margin-left: 20px;
            @include proxBold;
            font-size: 1.2rem;

            @include display_max_sm{
                display: none;
            }
        }
    }

    &.navbar-light{
        transition: .6s !important;
        .navbar-nav{
            .nav-link{
                color: $navy;
                text-decoration:none;
                &.active,&:hover {
                    color: $teal !important;
                }
                &.contact-link{
                    &:hover, &.active{
                        color: $white !important;
                    }
                }

                @include display_max_md {
                    color: $navy !important;
                }
            }
        } 
        #logoAlt{
            display: block;
            @include display_max_sm{
                width: 80px !important;
            }
        }
        #logo{
            display: none;
            @include display_max_sm{
                width: 80px !important;
            }
        }
        .companyName{
            color: $navy !important;
            text-transform: uppercase;
            margin-top: 8px;
            margin-left: 20px;
            @include proxBold;
            font-size: 1.2rem;
        }
    }
    @include display_max_md {
        position: sticky !important;
    }

    @media screen and (max-width: 767px) {
    display: none;
}
  
}
body.on-page-video{
    .mainNavbar {
        &.navbar-light{
            .navbar-nav{
                .nav-link{
                    color:$white;

                    &:hover {
                        color: $teal;
                    }
                }
            } 
            #logoAlt{
                display: none;
            }
            #logo{
                display: block;
                color: $teal;
            }
            .companyName{
                color: $white !important;
                text-transform: uppercase;
                margin-top: 8px;
                margin-left: 20px;
                @include proxBold;
                font-size: 1.2rem;
            }
        }
    }
}


.SideNav {

 
    z-index: 10022 !important;
    position: absolute;
    margin-top: 10px;
    right:8%;

    @media screen and (min-width: 768px) {
      
        display: none;
    }

    li{
        list-style: none !important;
        margin-left: -2%;
        margin-right: 10%;

        @media screen and (max-width: 310px) {
            margin-left: -10%;
            margin-right: 10%;
        }
    }

   
        h1 {

            @media screen and (max-width: 319px) {
            padding-bottom: 25% !important;
            }
        }
     
    @media screen and (max-width: 699px) and (min-width:570px) {
       right: 10%;
    }
    @media screen and (max-width: 569px) and (min-width:450px) {
       right: 11%;
    }
    @media screen and (max-width: 449px) and (min-width:360px) {
       right: 12%;
    }
    @media screen and (max-width: 359px)  {
       right: 14%;
    }
      
}



.menuIcon {
    right: 10px !important;
    padding-right: 5px;
    padding-left: 5px;
     background:  transparent;
    height: 44px;
    width: 44px;
    fill: $teal;
    border-radius: 5px;
    margin-left: 90%;
    margin-top: 5px;
    margin-bottom: 5px;
    animation: 2s fadeInText;
    animation-fill-mode: forwards;
    position: fixed;
    
    @media screen and (min-width: 768px) {
      
        display: none !important;
    }
}
body {
    &.on-page-video {
        .menuIcon {
            fill: $teal;
        }
    }
}

.logo3 {
    display: block;
    float: left;
}
.closeSubNav{
    float: right;
    color: #dadada;
}


.SideNav{
    li{
        .nav-item {
            a {
                color: $white;
            }
        }
    }
}

.subnav {
    top: 76px !important;
    background-color: $paleGrey !important;

    @include display_xs {

    }

    .subnavBrand {
        color: $navgrey;
        .svg-inline--fa {
            font-size: 1.4rem;
            margin-right: 10px;
        }

        @media screen and (max-width: 669px) {
            &.subnavContent {
                display: none;
            }
        }
        @media screen and (min-width: 670px) and (max-width: 700px) {
       border-right: 2px solid $navgrey;
       padding: 10px;
        }
       
    }

   

    ul{
        @include display_max_sm{
            display: flex;
            flex-direction: row !important;
            justify-content: center;
            align-self: baseline;
        }
        @include display_xs {
            margin-top: -10px;   
         }
         @media screen and (max-width: 669px) and (min-width: 575px) {
            width: 90% !important;
            margin-right: 5% !important;
            margin-left: 5% !important;
            justify-content: center;
        }

  

        li{
            @include display_max_sm{
                justify-content: center;
                padding: 10px;
            }
          
            @media screen and (max-width: 496px) and (min-width: 430px) {
                margin-left: 0%;
                margin-right: 0%;
            }
             @media screen and (max-width: 429px) and (min-width: 342px) {
                margin-left: -1%;
                margin-right: -1%;
                font-size:14px;
            }
             @media screen and (max-width: 341px) and (min-width: 300px) {
                margin-left: -2%;
                margin-right: -2%;
                font-size:13px;
                margin-top: 1px;
            }
        }
    }

    a{
        color: $navgrey;
        text-transform: uppercase;
        text-decoration: none !important;

        &.active,&:hover {
            color: $coral !important;
        }
    }
    &.navbar-light{
        .navbar-nav{
            .nav-link{
                color: $navgrey;

                &:hover {
                    color: $grey;
                }
            }
        } 
    } 
    @include display_max_lg {
        position: sticky !important;
        margin-top: 6px !important;
    }

    @include display_md {
        margin-top: -1px !important;
        position: fixed !important;
    }

     @include display_sm {
       height: 56px !important; 
       position: inherit !important;  
    } 
     @include display_xs {
       height: 56px !important;  
       position: inherit !important;  
    }
    
}
.companyName{
    @include display_xs {
        display: none;
    }
    @include display_sm {
        display: none;
    }
    @include display_md {
        display: none;
    }
}


