.video-header{
  width:100%;
  height:800px;
  overflow:hidden;
  position: relative;
  z-index: -1;

  @include display_max_lg{
    height:400px;
    top: -10vh;
  }

  video{
    max-height:700px;
    min-height:700px;
    width:100%;
    object-fit: cover;
    position: relative;
    height:auto;
    top: 0;
    transform: translateY(-15vh);

    @include display_max_lg{
      min-height:400px;
      width:100%;
      transform: translateY(-5vh);
    }
  }
}

.videoHomePageShrink{
  
  width: 86%;
  margin-top:100px;
  transition: .5s;

  // - added for Ipad
  // overflow-y: scroll;
  // -webkit-overflow-scrolling: touch;
  

  @include display_md {
    margin-top:0;
  }
  @include display_max_md {
    margin-top:0;
    width: 100% !important;
  }
}
.videoHomePageFull, #videoBkgrdPlaceholde{
  width: 100%;
  transition: .5s;

  // - added for Ipad
  // overflow-y: scroll;
  // -webkit-overflow-scrolling: touch;
}

html {
  scroll-behavior: smooth;
}

.navyText {
  color: $navy;
  @include display_max_md {
    color: $white;
  }
}

.txtHomePageVideoOverlay{
  @include proxNorm;
  margin: 0 5%;
  
  a{
    color: $white !important;
    font-size: 1.3rem;
    font-weight: 400;
    text-transform: uppercase;
    
    &:hover{
      text-decoration: none;
    }
    @include display_max_md {
      font-size: 1rem !important;
    }
  }
  @include display_md {
    margin: 0 5%;
    transition: .6s;
  }
}

.txtHomePageVideoOverlayNavy {
  margin: 0 5%;
  @include proxNorm;
  text-transform: uppercase;

    a {
    color: $navy;
    font-size: 1.3rem;
    font-weight: 400;

    &:hover{
      text-decoration: none;
    }

    @include display_max_md {
      font-size: 1rem !important;
      color: white !important;
    }

    @include display_md {
      color: white!important;
     
     
    }
  }

  @include display_md {
    margin: 0 3.8%;
    transition: .6s;
  }
}

.rightBox {
  transition: 1s ease-in-out;
  opacity: 1;
  visibility: visible;

  @include display_md {
  
    display: none !important;
  }
  @include display_max_md {
    display: none !important;
  }
}
.leftBox {
  transition: 1s ease-in-out;
  opacity: 1;
  visibility: visible;


  @include display_md {
    top: 25% !important;
    
    transform: rotate(0deg) !important;
    justify-content: center !important;
    right: -135px !important;
    top: 90% !important;
    margin: 0 16% !important;

  }
  
  @include display_max_md {
    transform: rotate(0deg) !important;
    justify-content: center !important;
    right: -140px !important;
    top: 90% !important;
  }
  a {
    @include display_md {
      font-size: 18px !important;
    }
  }
  p {
    @include display_max_md{
      margin: 0 2% !important;
    }
  }
  
}

@media only screen and (width: 1401px) {
  .leftBox {
    top:38% !important;
  }
  .rightBox {
    top:15% !important;
  }

}

 
@media only screen and (min-width: 1680px) {
  .leftBox {
    top: 42% !important;
  }
  .rightBox {
    top: 25% !important;
  }
}

.dontShow{
  transition: 1s ease-in-out;
  opacity: 0;
  visibility: hidden;
}



@media only screen and (max-width: 1476px) and (min-width: 1110px) {
  .sideClockEdi {
    margin-top: 200% !important;
  }
}

@media only screen and (max-width: 1099px) and (min-width: 993px) {
  .txtHomePageVideoOverlay{
    a {
      font-size: 1.1rem !important;
  }
}
  .txtHomePageVideoOverlayNavy{
    a{
      font-size: 1.1rem !important;
    }
  }

  .leftBox {
    top: 35% !important;
  }
}

@media only screen and (max-width: 1400px) and (min-width: 1310px) {
    
  .rightBox {
      margin-right: 40px !important;
    
    }
  .txtHomePageVideoOverlay{
    a {
      font-size: 1.2rem !important;
    }
  }
  .txtHomePageVideoOverlayNavy{
    a{
      font-size: 1.2rem !important;
    }
  }
  .sideClockSan {
    margin-top: 135% !important;
  }
  .sideClockDub {
    margin-top: 170% !important;
  }
  .rightBox {
    margin-top: -4.5% !important;
  }
}

@media only screen and (max-width: 1309px) and (min-width: 1200px) {
  
  .socialIcon{
    margin: 10px 0 !important;
  }
  .sideClockEdi {
    margin-top: 160% !important;
  }
  .sideClockSan {
    margin-top: 135% !important;
  }
  .sideClockDub {
    margin-top: 165% !important;
  }
  .leftBox {
    
      left: -115px !important;
    
  }
  .rightBox {
    margin-top: -2% !important;
  }


  .txtHomePageVideoOverlay{
    a{
      font-size: 1.1rem !important;
    }
  }
  .txtHomePageVideoOverlayNavy{
    a {
      font-size: 1.1rem !important;
    }
  }
}

@media screen and (max-width: 1199px) { 
    .sideClockDub, .sideClockEdi, .sideClockSan{
      display: none;
    }
    .rightBox {
      top: 30% !important;
    }
}


@media only screen and (max-width: 1199px) and (min-width: 992px) {
  
  .rightBox {
    margin-right: 30px !important;
  }
  .txtHomePageVideoOverlay{
    a{
      font-size: 1rem !important;
    }
  }
  .txtHomePageVideoOverlayNavy{
    a{
      font-size: 1rem !important;
    }
  }
  .leftBox {
    left: -110px !important;
  }

}


@media screen and (max-width: 540px) { 
  .leftBox {
    display: none !important;
  }
}